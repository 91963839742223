import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNull as _isNull } from 'lodash'
import { createCertificate, getCertificates, searchCertificates } from 'Api/certificate.service.ts'
import { CertificateType, PaginationProps } from 'Types/global.types.ts'

type CertificateSliceProps = {
  initialCertificates: CertificateType[]
  certificates: CertificateType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
}

const initialState = {
  initialCertificates: [],
  certificates: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
} as CertificateSliceProps

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    setCertificatePageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCertificates.fulfilled, (state, action) => {
        if (action.payload) {
          // @ts-ignore
          state.certificates = action.payload.certificates
          state.total = action.payload.total
          // @ts-ignore
          state.initialCertificates = action.payload.certificates
        }
        state.loading = false
      })
      .addCase(getCertificates.pending, (state) => {
        state.loading = true
      })
      .addCase(getCertificates.rejected, (state) => {
        state.loading = false
      })
      .addCase(createCertificate.fulfilled, (state, action) => {
        if (action.payload) {
          state.certificates.unshift(action.payload)
          state.initialCertificates.unshift(action.payload)
          state.loading = false
          state.total += 1
          state.pageIndex = 0
        }
      })
      .addCase(createCertificate.pending, (state) => {
        state.loading = true
      })
      .addCase(createCertificate.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCertificates.fulfilled, (state, action: PayloadAction<CertificateType>) => {
        if (!_isNull(action.payload)) {
          // @ts-ignore
          state.certificates = action.payload
        } else {
          state.certificates = [...state.initialCertificates]
        }
      })
  },
})

const { actions } = certificatesSlice

export const { setCertificatePageIndex } = actions
export default certificatesSlice.reducer
