import { createSlice } from '@reduxjs/toolkit'
import { createPrice, getCountryPrices, getDefaultPrices, getUserPrices } from 'Api/admin/admin.pricing.service.ts'
import { PriceType } from 'Types/admin/admin'
import { DefaultPriceProps } from 'Pages/AdminPricing/AdminPricing'

interface PriceProps {
  certificate: { [key: string]: PriceType[] }
  purchase: { [key: string]: PriceType[] }
}

interface CustomerPriceProps {
  certificate: PriceType[]
  purchase: PriceType[]
}

interface PricesState {
  defaultPrice: DefaultPriceProps
  countryPrices: PriceProps
  userPrices: CustomerPriceProps
}

const initialState: PricesState = {
  defaultPrice: null,
  countryPrices: null,
  userPrices: null,
}

export const pricesSlice = createSlice({
  name: 'pricesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPrice.fulfilled, (state, action) => {
        if (action.payload) {
          switch (action.payload.price.type) {
            case 'DEFAULT': {
              if (action.payload.price.for === 'CERTIFICATE') {
                state.defaultPrice.certificate.push(action.payload.price)
              } else if (action.payload.price.for === 'PURCHASE') {
                state.defaultPrice.purchase.push(action.payload.price)
              }
              break
            }
            case 'COUNTRY': {
              const countryName = action.payload.price.country
              if (action.payload.price.for === 'CERTIFICATE') {
                if (state.countryPrices.certificate[countryName]) {
                  state.countryPrices.certificate[countryName].push(action.payload.price)
                } else {
                  state.countryPrices.certificate[countryName] = [action.payload.price]
                }
              } else if (action.payload.price.for === 'PURCHASE') {
                if (state.countryPrices.purchase[countryName]) {
                  state.countryPrices.purchase[countryName].push(action.payload.price)
                } else {
                  state.countryPrices.purchase[countryName] = [action.payload.price]
                }
              }
              break
            }
          }
        }
      })
      .addCase(getDefaultPrices.fulfilled, (state, action) => {
        if (action.payload) {
          state.defaultPrice = action.payload.prices
        }
      })
      .addCase(getCountryPrices.fulfilled, (state, action) => {
        state.countryPrices = action.payload.prices
      })
      .addCase(getUserPrices.fulfilled, (state, action) => {
        if (action.payload) {
          // FIX ESLINT
          // @ts-ignore
          state.userPrices = action.payload.prices
        }
      })
  },
})

export default pricesSlice.reducer
