import SvgIcon from 'Components/common/SvgIcon'

export const getIconByType = (type: string) => {
  switch (type) {
    case 'success': {
      return <SvgIcon icon="check" color="#80c570" />
    }
    case 'error': {
      return <SvgIcon icon="remove" color="#FF4757" />
    }
    case 'warning': {
      return <SvgIcon icon="error" color="#F5BA5C" />
    }
    case 'info': {
      return <SvgIcon icon="warning" color="#5352ED" />
    }
    default: {
      return <SvgIcon icon="check" color="#5352ED" />
    }
  }
}

export const contextClass = {
  success: 'success-message',
  error: 'error-message ',
  info: 'info-message toast-item',
  warning: 'warning-message toast-item',
  default: 'info-message toast-item',
}
