import React from 'react'

export enum ButtonType {
  Primary = 'primary',
  PrimaryMinimal = 'primaryMinimal',
  Secondary = 'secondary',
  SecondaryTransparent = 'secondaryTransparent',
  Error = 'error',
  Disabled = 'disabled',
  Transparent = 'transparent',
  TransparentMinimal = 'transparentMinimal',
  Link = 'link',
  Square = 'square',
  Download = 'download',
  MarketingPrimary = 'marketingPrimary',
  MarketingSecondary = 'marketingSecondary',
  MarketingTransparent = 'marketingTransparent',
  MarketingTransparentMinimal = 'marketingTransparentMinimal'
}

export type ButtonTypeNames =
  | 'primary'
  | 'primaryMinimal'
  | 'secondary'
  | 'secondaryTransparent'
  | 'error'
  | 'disabled'
  | 'transparent'
  | 'transparentMinimal'
  | 'link'
  | 'square'
  | 'download'
  | 'marketingPrimary'
  | 'marketingSecondary'
  | 'marketingTransparent'
  | 'marketingTransparentMinimal'
  | undefined

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset'
  children?: React.ReactNode
  buttonType?: ButtonTypeNames
  loader?: boolean
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
