import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Eur1CounterType } from 'Types/admin/admin'
import { PaginationProps } from 'Types/global.types.ts'

import { createEur1Counter, getEur1CounterList, removeEur1Counter } from 'Api/admin/admin.eur1Counter.service.ts'

interface InitialStateProps {
  eur1CounterList: Eur1CounterType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  eur1CounterList: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
}

const adminEur1CounterSlice = createSlice({
  name: 'adminEur1Counter',
  initialState,
  reducers: {
    setEur1CounterPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEur1CounterList.fulfilled, (state, action) => {
        if (action.payload) {
          state.eur1CounterList = action.payload.eur1CounterList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getEur1CounterList.pending, (state) => {
        state.loading = true
      })
      .addCase(getEur1CounterList.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeEur1Counter.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.eur1CounterList = state.eur1CounterList.filter((counter) => counter.id !== action.payload.id)
        }
      })
      .addCase(createEur1Counter.fulfilled, (state, action) => {
        if (action.payload) {
          state.eur1CounterList.unshift(action.payload.eur1Counter)
        }
      })
  },
})

const { actions } = adminEur1CounterSlice

export const { setEur1CounterPageIndex } = actions

export default adminEur1CounterSlice.reducer
