import { createSlice } from '@reduxjs/toolkit'

import { LabelValueProps } from 'Types/admin/admin'

import { getDiagramByType, getMonthlyIncome, getYearlyIncome } from 'Api/admin/admin.statistic.service.ts'

type InitialPortalStateProps = {
  monthlyIncome: number
  yearlyIncome: number
  diagram: LabelValueProps[]
}

const initialState: InitialPortalStateProps = {
  monthlyIncome: 0,
  yearlyIncome: 0,
  diagram: [],
}

export const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMonthlyIncome.fulfilled, (state, action) => {
        state.monthlyIncome = action.payload.income.monthly
      })
      .addCase(getYearlyIncome.fulfilled, (state, action) => {
        state.yearlyIncome = action.payload.income.yearly
      })
      .addCase(getDiagramByType.fulfilled, (state, action) => {
        state.diagram = action.payload.diagram
      })
  },
})

export default statisticSlice.reducer
