import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FormikValues } from 'formik'

import { CertificateListResponseType, CertificateResponseType, PaginationProps } from 'Types/global.types.ts'
import {
  CERTIFICATE_API,
  CERTIFICATE_BASE_API_URL,
  DOWNLOAD_API,
  MIN_REQUIRED_SEARCH_CHARACTERS,
} from 'Constants/Global.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get a list of certificates
 */
export const getCertificates = createAsyncThunk(
  'certificates/getCertificates',
  async ({ pageIndex, pageSize }: PaginationProps) => {
    const token = getAccessToken()
    const url = `${CERTIFICATE_BASE_API_URL}?page=${pageIndex}&limit=${pageSize}`

    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CertificateListResponseType> = await axios.get(url, config)
      return {
        certificates: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Create a new certificate
 */
export const createCertificate = createAsyncThunk('certificates/createCertificate', async (options: FormikValues) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    const { data }: AxiosResponse<CertificateResponseType> = await axios.post(CERTIFICATE_BASE_API_URL, options, config)
    return data.data
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Search certificates by:
 * - vin, registration (non-forwarder)
 * - buyer, company name (additional for forwarder)
 */
export const searchCertificates = createAsyncThunk('certificates/search', async (keyword: string) => {
  const url = `${CERTIFICATE_API.CERTIFICATE_SEARCH}?param=${keyword}`
  const token = getAccessToken()
  const charLength = keyword.length

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    if (charLength >= MIN_REQUIRED_SEARCH_CHARACTERS) {
      const { data }: AxiosResponse<CertificateResponseType> = await axios.get(url, config)
      return data.data
    } else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const getFile = (filePath: string) =>
  new Promise((resolve, reject) => {
    const url = `${DOWNLOAD_API.GET_FILE}${filePath}`
    const token = getAccessToken()
    const config = {
      responseType: 'blob',
      headers: {
        Authorization: token,
      },
    }

    axios
      // @ts-ignore
      .get(url, config)
      .then((data) => {
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
