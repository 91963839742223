import React, { ReactNode } from 'react'
import { StyledButtonWrapper } from 'Components/common/ButtonWrapper/ButtonWrapper.styled.ts'

type ButtonWrapper = {
  children: ReactNode
}

const ButtonWrapper: React.FC<ButtonWrapper> = ({ children }: ButtonWrapper): JSX.Element => {
  const buttonLength = React.Children.count(children)
  return (
    <StyledButtonWrapper buttonLength={buttonLength} className="StyledButtonWrapper">
      {children}
    </StyledButtonWrapper>
  )
}

export default ButtonWrapper
