import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { FormikValues } from 'formik'

import { SHIPPING_ADDRESS } from 'Constants/Global.constants.ts'
import { PaginationProps, ShippingAddressResponse, ShippingAddressType } from 'Types/global.types.ts'

import { handleError } from 'Helpers/errors.helper.ts'
import { getAccessToken } from 'Utils/auth.util.ts'

type ShippingAddressApiType = {
  id?: string
  userId?: string
  firstName: string
  lastName: string
  country: string
  city: string
  address: string
  postalCode: string
  phone: string
  isPrimary?: boolean
  updatedAt?: string
  createdAt?: string
}

type ShippingAddressApiResponseType = {
  data: ShippingAddressApiType[]
  total: number
}

const getFormattedShippingAddressList = (input: ShippingAddressApiType[]): ShippingAddressType[] =>
  input.map((address) => {
    const mutableCountry = {
      label: address?.country,
      value: address?.country,
    }

    return {
      ...address,
      country: mutableCountry,
    }
  })

export const addShippingAddress = createAsyncThunk('shippingAddress/add', async (options: FormikValues) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: ShippingAddressApiType }> = await axios.post(
      SHIPPING_ADDRESS,
      options,
      config
    )
    const mutableShippingAddress: ShippingAddressType = {
      ...data.data,
      country: {
        label: data.data?.country,
        value: data.data?.country,
      },
    }
    return {
      shippingAddress: mutableShippingAddress,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const getShippingAddress = createAsyncThunk(
  'shippingAddress/get',
  async ({ pageIndex, pageSize }: PaginationProps) => {
    const token = getAccessToken()
    const url = `${SHIPPING_ADDRESS}?page=${pageIndex}&limit=${pageSize}`

    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<ShippingAddressApiResponseType> = await axios.get(url, config)
      const mutableShippingAddressList: ShippingAddressType[] = getFormattedShippingAddressList(data.data)

      return {
        shippingAddressList: mutableShippingAddressList,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

/**
 * Get primary address
 */
export const getPrimaryShippingAddress = createAsyncThunk('shippingAddress/getPrimaryShippingAddress', async () => {
  const token = getAccessToken()
  const url = `${SHIPPING_ADDRESS}/primary`

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: ShippingAddressApiType }> = await axios.get(url, config)
    const mutableShippingAddress: ShippingAddressType = {
      ...data.data,
      country: {
        label: data.data?.country,
        value: data.data?.country,
      },
    }
    return {
      primaryShippingAddress: mutableShippingAddress,
    }
  } catch (error) {
    handleError(error)
  }
})

/**
 * Update shipping address
 */
export const updateShippingAddress = createAsyncThunk(
  'shippingAddress/update',
  async (payload: { id: string; options: ShippingAddressType }) => {
    const { id, options } = payload
    const url = `${SHIPPING_ADDRESS}/${id}`
    const token = getAccessToken()

    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: ShippingAddressApiType }> = await axios.patch(url, options, config)
      const mutableShippingAddress: ShippingAddressType = {
        ...data.data,
        country: {
          label: data.data?.country,
          value: data.data?.country,
        },
      }
      return {
        shippingAddress: mutableShippingAddress,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const removeShippingAddress = createAsyncThunk('shippingAddress/remove', async (id: string) => {
  const url = `${SHIPPING_ADDRESS}/${id}`
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<ShippingAddressResponse> = await axios.delete(url, config)
    return { ...data.data, id }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})
