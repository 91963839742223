import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationProps, SellerType } from 'Types/global.types.ts'
import {
  addAdminSeller,
  changeSellerStatus,
  getAdminSellers,
  updateAdminSeller,
  updateAdminSellerStatus,
} from 'Api/admin/admin.seller.service.ts'

interface InitialStateProps {
  adminSellers: SellerType[]
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  adminSellers: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
}

const adminSellersSlice = createSlice({
  name: 'adminSellers',
  initialState,
  reducers: {
    setSellerPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminSellers.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = action.payload.adminSellers
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getAdminSellers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAdminSellers.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateAdminSeller.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
      .addCase(addAdminSeller.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers.unshift(action.payload.seller)
          state.total += 1
        }
      })
      .addCase(updateAdminSellerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
      .addCase(changeSellerStatus.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminSellers = state.adminSellers.map((seller) => {
            if (seller.id === action.payload.seller.id) {
              return action.payload.seller
            } else {
              return seller
            }
          })
        }
      })
  },
})

const { actions } = adminSellersSlice

export const { setSellerPageIndex } = actions

export default adminSellersSlice.reducer
