import iconSet from 'Assets/svg-icons.json'

import { StyledSvgIcon } from './SvgIcon.styled.ts'

type SvgIconProps = {
  color?: string
  size?: string | number
  icon: string
  className?: string
  layout?: boolean
}

const SvgIcon = ({ color = '#000000', size = 20, icon, className = 'StyledSvgIcon', layout }: SvgIconProps): JSX.Element => (
  <StyledSvgIcon className={className} iconSet={iconSet} color={color} size={size} icon={icon} layout={layout} />
)

export default SvgIcon
