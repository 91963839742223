import { createSlice } from '@reduxjs/toolkit'
import { UserTypes } from 'Types/user.types.ts'
import { finishOnboarding, getUser, updateUser } from 'Api/user.service.ts'

interface UserState {
  user: UserTypes | null
  userLoaded: boolean
  userLoading: boolean
}

const initialState: UserState = {
  user: null,
  userLoaded: false,
  userLoading: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser: (state) => {
      state.user = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(finishOnboarding.fulfilled, (state, action) => {
        if (action.payload) {
          state.user = action.payload
        }
      })
  },
})

export const { removeUser } = userSlice.actions
export default userSlice.reducer
