import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAuctionHouseList } from 'Api/auction.service.ts'
import { AuctionHouseType } from 'Types/auctionHouse'

interface InitialState {
  auctionHouseList: AuctionHouseType[]
  total: number
  pageIndex: number
  loading: boolean
  loaded: boolean
}

const initialState: InitialState = {
  auctionHouseList: [],
  total: 0,
  loading: false,
  loaded: false,
  pageIndex: 0,
}

const auctionHouseSlice = createSlice({
  name: 'auctionHouse',
  initialState,
  reducers: {
    addAuctionHouseToList: (state, action: PayloadAction<AuctionHouseType>) => {
      if (action.payload) {
        state.auctionHouseList.unshift(action.payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuctionHouseList.fulfilled, (state, action) => {
        if (action.payload) {
          state.auctionHouseList = action.payload.auctionHouseList
          state.total = action.payload.total
          state.pageIndex = 0
          state.loaded = true
        }
        state.loading = false
      })
      .addCase(getAuctionHouseList.pending, (state) => {
        state.loading = true
        state.loaded = false
      })
      .addCase(getAuctionHouseList.rejected, (state) => {
        state.loading = false
        state.loaded = false
      })
  },
})

const { actions } = auctionHouseSlice
export const { addAuctionHouseToList } = actions
export default auctionHouseSlice.reducer
