import { StyledButton, StyledSpinner } from './Button.styled.ts'

import { ButtonProps, ButtonType } from 'Types/button.types.ts'
import SvgIcon from 'Components/common/SvgIcon'
import { StyledCircle } from 'Styles/Global.ts'

const Button = ({ children, loader, type, ...rest }: ButtonProps): JSX.Element => (
  <StyledButton className="StyledButton" {...rest} type={type || 'button'}>
    {loader ? (
      <>
        <StyledSpinner className="StyledSpinner">
          <SvgIcon color="white" icon="load" />
        </StyledSpinner>
      </>
    ) : (
      <div className="children-content">{children}</div>
    )}
    {rest.buttonType === ButtonType.Download && (
      <>
        <StyledCircle>
          <SvgIcon icon="download" size={18} color="#5352ED" />
        </StyledCircle>
      </>
    )}
  </StyledButton>
)

export default Button
