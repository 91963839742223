import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DashboardDataType } from 'Types/admin/admin'

interface InitialStateProps {
  dashboardDataType: DashboardDataType
}

const initialState: InitialStateProps = {
  dashboardDataType: 'SERVICE_INCOME',
}

const adminDashboardSlice = createSlice({
  name: 'adminDashboardSlice',
  initialState,
  reducers: {
    setDashboardDataType: (state, action: PayloadAction<DashboardDataType>) => {
      state.dashboardDataType = action.payload
    },
  },
})

const { actions } = adminDashboardSlice
export const { setDashboardDataType } = actions
export default adminDashboardSlice.reducer
