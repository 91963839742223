import styled from 'styled-components'

interface ButtonWrapperProps {
  buttonLength: number
}

export const StyledButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: ${({ buttonLength }) => (buttonLength > 1 ? 'space-between' : 'flex-end')};
  width: 100%;
`
