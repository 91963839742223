import React from 'react'
import 'react-toastify/dist/ReactToastify.css'

const Routes = React.lazy(() => import('./routes/routes.tsx'))

import Drawer from 'Components/common/Drawer'
import Toastify from 'Components/common/Toastify'
import Portal from 'Components/common/Portal'

function App() {
  return (
    <>
      <Routes>
        <Drawer />
        <Portal />
      </Routes>
      <Toastify />
    </>
  )
}

export default App
