import { useEffect, useRef, MutableRefObject } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { DrawerComponentProps } from './Drawer.types.ts'
import { useAppDispatch, useAppSelector } from 'Stores/hooks.ts'
import { closeDrawer } from 'Stores/drawer/drawer.slice.ts'

import SvgIcon from '../SvgIcon'

import { StyledDrawer, StyledDrawerHeader, StyledDrawerBody, StyledPreventOverlay } from './Drawer.styled.ts'

const Drawer = ({ className, ...rest }: DrawerComponentProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isOpen = rest?.drawer?.isOpen
  const isPreventClose = rest?.drawer?.options?.preventClose
  const { drawerTitle = '', drawerWidth } = rest.drawer.options || {}
  const { user } = useAppSelector((state) => state.user)

  const bodyRef: MutableRefObject<HTMLBodyElement | null> = useRef(document.querySelector('body'))
  const drawerRef = useRef<HTMLDivElement | null>(null)

  const handleCloseDrawer = () => {
    dispatch(closeDrawer())
  }

  const isUserOnboarding = () => {
    const isDashboard = location.pathname === '/dashboard'
    const isOnboardingUncompleted = user && !user.finishedOnboarding
    return isDashboard && isOnboardingUncompleted
  }

  const handleClickOutsidePortal = (event: MouseEvent) => {
    const isOnboardingInProgress = isUserOnboarding()

    if (drawerRef.current && !drawerRef.current.contains(event.target as Node) && !isOnboardingInProgress) {
      handleCloseDrawer()
    }
  }

  const handleEscKey = (event: KeyboardEvent) => {
    const isOnboardingInProgress = isUserOnboarding()

    if (event.key === 'Escape' && !isOnboardingInProgress) {
      dispatch(closeDrawer())
    }
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleEscKey)
      document.addEventListener('mousedown', handleClickOutsidePortal)
    } else {
      document.removeEventListener('mousedown', handleClickOutsidePortal)
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey)
      document.removeEventListener('mousedown', handleClickOutsidePortal)
    }
  }, [isOpen])

  useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen && bodyRef.current) {
        bodyRef.current.style.overflow = 'hidden'
      } else if (bodyRef.current) {
        bodyRef.current.style.overflow = ''
      }
    }

    updatePageScroll()
  }, [isOpen])

  const useDrawerElements = () => {
    const InnerComponent = rest?.drawer?.type

    const innerComponentProps = {
      drawerOptions: {
        ...rest.drawer.options,
      },
    }

    if (InnerComponent) {
      return <InnerComponent {...innerComponentProps} />
    }
    return <></>
  }

  return (
    <>
      <StyledDrawer
        aria-hidden={isOpen ? 'false' : 'true'}
        className={classNames('StyledDrawer', { open: isOpen, className })}
        drawerWidth={drawerWidth}
        ref={drawerRef}
      >
        <StyledDrawerHeader className="StyledDrawerHeader">
          {!isPreventClose && (
            <div className="drawer-close-btn" onClick={handleCloseDrawer}>
              <SvgIcon size={12} icon="close" />
            </div>
          )}
          <div className="drawer-subtitle">{drawerTitle}</div>
        </StyledDrawerHeader>

        <StyledDrawerBody className="StyledDrawerBody">{useDrawerElements()}</StyledDrawerBody>
      </StyledDrawer>
      {isOpen && <StyledPreventOverlay />}
    </>
  )
}

export default Drawer
