import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AdminPaymentResponseType, AdminPaymentType } from 'Types/admin/admin'
import { CancelFacturePayloadProps, FactureType } from 'Types/global.types.ts'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get invoices by month
 */
export const downloadInvoice = createAsyncThunk(
  'admin/downloadInvoice',
  async ({ month = '', year = '1' }: { month: string; year?: string }) => {
    let url = ADMIN_API_URL.FACTURE.MONTHLY_DOWNLOAD
    const queryParams = []

    if (month) {
      queryParams.push(`month=${encodeURIComponent(month)}`)
    }

    if (year) {
      queryParams.push(`year=${encodeURIComponent(year)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      responseType: 'blob',
      headers: {
        Authorization: token,
      },
    }

    try {
      // @ts-ignore
      const data: AxiosResponse = await axios.get(url, config)
      return data
    } catch (error) {
      handleError(error)
    }
  }
)

type InvoicesRequestProps = {
  userId?: string
  param?: string
  sort?: 'CREATED_AT' | 'PAID'
  order?: 'ASC' | 'DESC'
  minDate?: string
  maxDate?: string
  pageIndex: number
  pageSize: number
}

export const getInvoicesByMonth = createAsyncThunk(
  'admin/getInvoicesByMonth',
  async ({
    userId,
    param,
    sort = 'CREATED_AT',
    order = 'DESC',
    minDate,
    maxDate,
    pageIndex,
    pageSize,
  }: InvoicesRequestProps) => {
    let url = ADMIN_API_URL.FACTURE.SEARCH
    const queryParams = []

    if (userId) {
      queryParams.push(`userId=${encodeURIComponent(userId)}`)
    }

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (sort) {
      queryParams.push(`sort=${encodeURIComponent(sort)}`)
    }

    if (order) {
      queryParams.push(`order=${encodeURIComponent(order)}`)
    }

    if (minDate) {
      queryParams.push(`minDate=${encodeURIComponent(minDate)}`)
    }

    if (maxDate) {
      queryParams.push(`maxDate=${encodeURIComponent(maxDate)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPaymentResponseType> = await axios.get(url, config)
      return {
        invoices: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

export const updateInvoicePaymentStatus = createAsyncThunk('admin/updateInvoicePaymentStatus', async (id: string) => {
  const url = `${ADMIN_API_URL.FACTURE.ROOT}/${id}/paid`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AdminPaymentType }> = await axios.patch(url, null, config)
    return {
      invoice: data.data,
    }
  } catch (error) {
    handleError(error)
  }
})

type ProductProps = {
  reference: string
  title: string
  unitPrice: number
  quantity: number
}

interface CustomFactureProps {
  customer: {
    id: string
    paymentMethod: string
    shippingCost: number
    delivery: string
  }
  products: ProductProps[]
}

/**
 * Create a custom facture
 */
export const createCustomFacture = createAsyncThunk('admin/addCustomFacture', async (options: CustomFactureProps) => {
  const url = `${ADMIN_API_URL.FACTURE.ROOT}/custom`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AdminPaymentType }> = await axios.post(url, options, config)
    return {
      invoice: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const toggleFactureText = createAsyncThunk(
  'admin/toggleFactureText',
  async ({ factureId, checkboxText }: { factureId: string; checkboxText: string }) => {
    const url = `${ADMIN_API_URL.FACTURE.ROOT}/${factureId}/checkboxText`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AdminPaymentType }> = await axios.patch(url, { checkboxText }, config)
      return data.data
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const cancelFacture = createAsyncThunk(
  'admin/cancelFacture',
  async ({ factureId, entityType }: CancelFacturePayloadProps) => {
    const url = `${ADMIN_API_URL.FACTURE.ROOT}/${factureId}/cancel`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: FactureType }> = await axios.patch(url, null, config)
      return {
        facture: data.data,
        entityType,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
