import { format, parseISO } from 'date-fns'
import { Country } from 'country-state-city'
import { forEach as _forEach, includes as _includes, isEqual as _isEqual } from 'lodash'
import { FormikValues } from 'formik'

export const formatDateToISOString = (date: Date | number | undefined, formatTo?: string) => {
  const dateFormat = formatTo || 'yyyy-MM-dd'
  return format(date, dateFormat)
}

export const getFriendlyDateFormat = (date: string, formatTo?: string) => {
  const desiredFormat = formatTo || 'dd.MM.yyyy'
  const parsedDate = parseISO(date)
  return formatDateToISOString(parsedDate, desiredFormat)
}

export const formatDateToIso = (date: string | undefined) => {
  if (date) {
    const parsedDate = parseISO(date)
    return format(parsedDate, 'yyyy-MM-dd')
  }
}

type ValuesType = Record<string, any>
export const getDiffValues = (values: ValuesType, initialValues: ValuesType): ValuesType => {
  return Object.keys(values).reduce((diff: ValuesType, key: string) => {
    if (!_isEqual(values[key], initialValues[key])) {
      diff[key] = values[key]
    }
    return diff
  }, {})
}

export const convertArrayToSelectFormat = <T, K extends keyof T>(
  items: T[],
  options: { value: K; label: K }
): { value: T[K]; label: T[K] }[] => {
  return items.map((item) => {
    const value = item[options.value]
    const label = item[options.label]

    return {
      value,
      label,
    }
  })
}

export const convertArrayToSelectFormatForAsync = (
  items: Array<any>,
  options: { label: string | Array<string>; value: string }
) => {
  return items.map((item: { [key: string]: any }) => {
    if (Array.isArray(options.label)) {
      const label = options.label
        .map((key) => {
          const result: string = item[key]
          if (result) {
            return result
          } else {
            return null
          }
        })
        .join(' ')

      const value = item[options.value]

      return {
        value,
        label,
        item,
      }
    } else {
      const value = item[options.value]
      const label = item[options.label]

      return {
        value,
        label,
        item,
      }
    }
  })
}

export const convertBuyersArrayToSelectFormat = (
  items: Array<{ [key: string]: any }>,
  options: { value: string; label: string }
): { value: string; label: string }[] => {
  return items.map((item) => {
    const value = item[options.value]
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const label = item.type === 'PHYSICAL' ? `${item['firstName']} ${item['lastName']}` : item['companyName']

    return {
      value,
      label,
    }
  })
}

/**
 * Get country list
 */
export const getCountryList = () => {
  const allCountries = Country.getAllCountries()
  return allCountries.map((country) => ({
    value: country.isoCode,
    label: country.name,
  }))
}

export const dateToDatepickerFormat = (date: Date) => {
  const desiredFormat = 'dd.MM.yyyy'
  const parsedDate = parseISO(date.toString())
  return formatDateToISOString(parsedDate, desiredFormat)
}

/**
 * Convert Date to ISO string
 * @param date
 */
export const dateToApi = (date: Date): string => {
  if (date) {
    return format(date, 'yyyy-MM-dd')
  }
  return null
}

type FormattedValues = Record<string, any>

type DataTypeProps = {
  dates?: Array<string>
  files?: Array<string>
}

export const getFormData = (formattedValues: FormattedValues, dataTypes: DataTypeProps) => {
  const formDataText = new FormData()
  const formDataBlob = new FormData()

  _forEach(formattedValues, (value, key) => {
    if (_includes(dataTypes.files, key)) {
      const mutableValue: File = value
      formDataBlob.append(key, mutableValue)
    } else if (_includes(dataTypes.dates, key)) {
      const dateValue: Date = value
      formDataText.append(key, dateToApi(dateValue))
    } else {
      const mutableValue: string = value
      formDataText.append(key, mutableValue)
    }
  })

  const mergedData = new FormData()
  for (const [key, value] of formDataText.entries()) {
    mergedData.append(key, value)
  }

  for (const [key, value] of formDataBlob.entries()) {
    mergedData.append(key, value)
  }

  return mergedData
}

export const isFieldsFilled = (fields: string[], values: FormikValues): boolean => {
  return fields.every((fieldName: string) => !!values[fieldName])
}

export const isAnyFieldsFilled = (fields: string[], values: FormikValues): boolean => {
  return fields.every((fieldName: string) => !!values[fieldName] || values[fieldName] === 0)
}

export const clearFormFields = (fields: string[], setFieldFn: (name: string, value: string) => void) => {
  fields.map((field) => setFieldFn(field, ''))
}

export const logFormDataEntries = (formData: FormData) => {
  for (const pair of formData.entries()) {
    const [key, value] = pair
    console.warn(`${key} - ${value as string}`)
  }
}
