import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { CustomerResponseType, PriceForTypeEnum, PriceType, RequestPayloadProps } from 'Types/admin/admin'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'
import { FactureType } from 'Types/global.types.ts'
import { DefaultPriceProps } from 'Pages/AdminPricing/AdminPricing'

interface PriceProps {
  certificate: { [key: string]: PriceType[] }
  purchase: { [key: string]: PriceType[] }
}

interface CreatePriceResponse {
  data: PriceType
}

interface PriceResponseType {
  data: PriceProps
}

export interface DefaultPriceResponseType {
  data: DefaultPriceProps
}

interface CountryRequestPayloadProps {
  country?: string
  priceFor?: PriceForTypeEnum
}

interface DefaultRequestPayloadProps {
  priceFor?: PriceForTypeEnum
}

interface UserRequestPayloadProps {
  priceFor?: PriceForTypeEnum
  userId?: string
}

interface CustomRequestPayloadProps {
  priceId: string
  options: {
    price: number
    minRange: number
    maxRange: number
  }
}

export const createPrice = createAsyncThunk('admin/createPrice', async (options: RequestPayloadProps) => {
  const url = ADMIN_API_URL.PRICE.ROOT
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CreatePriceResponse> = await axios.post(url, options, config)
    return {
      price: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const updateFactureArticlePrice = createAsyncThunk(
  'admin/createArticlePrice',
  async ({ factureId, options }: { factureId: string; options: { price: number } }) => {
    const url = `${ADMIN_API_URL.FACTURE.ROOT}/${factureId}/price`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: FactureType }> = await axios.patch(url, options, config)
      return {
        facture: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getDefaultPrices = createAsyncThunk(
  'admin/getDefaultPrices',
  async ({ priceFor }: DefaultRequestPayloadProps) => {
    let url = `${ADMIN_API_URL.PRICE.ROOT}/default`
    const queryParams = []

    if (priceFor) {
      queryParams.push(`for=${encodeURIComponent(priceFor)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<DefaultPriceResponseType> = await axios.get(url, config)
      return {
        prices: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getCountryPrices = createAsyncThunk(
  'admin/getCountryPrices',
  async (event?: CountryRequestPayloadProps) => {
    const { priceFor, country } = event || {}
    let url = `${ADMIN_API_URL.PRICE.ROOT}/country`
    const queryParams = []

    if (priceFor) {
      queryParams.push(`for=${encodeURIComponent(priceFor)}`)
    }

    if (country) {
      queryParams.push(`country=${encodeURIComponent(country)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<PriceResponseType> = await axios.get(url, config)
      return {
        prices: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getUserPrices = createAsyncThunk(
  'admin/getUserPrices',
  async ({ priceFor, userId }: UserRequestPayloadProps) => {
    let url = `${ADMIN_API_URL.PRICE.ROOT}/user`
    const queryParams = []

    if (priceFor) {
      queryParams.push(`for=${encodeURIComponent(priceFor)}`)
    }

    if (userId) {
      queryParams.push(`userId=${encodeURIComponent(userId)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomerResponseType> = await axios.get(url, config)
      return {
        prices: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getUserRange = async ({ userId, priceFor }: { userId: string; priceFor?: 'CERTIFICATE' | 'PURCHASE' }) => {
  let url = `${ADMIN_API_URL.PRICE.ROOT}/user-range`
  const queryParams = []

  if (userId) {
    queryParams.push(`userId=${encodeURIComponent(userId)}`)
  }

  if (priceFor) {
    queryParams.push(`for=${encodeURIComponent(priceFor)}`)
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join('&')
    url = `${url}?${queryString}`
  }

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomerResponseType> = await axios.get(url, config)
    return {
      prices: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

export const createUserRange = async (options: {
  for?: 'CERTIFICATE' | 'PURCHASE'
  minRange: number
  maxRange: number
  price: number
  userId: string
}) => {
  const url = `${ADMIN_API_URL.PRICE.ROOT}/user-range`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomerResponseType> = await axios.post(url, options, config)
    return {
      prices: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * This endpoint is used only for CUSTOMER
 * @param priceId
 * @param options
 */
export const updateUserRange = async ({
  priceId,
  options,
}: {
  priceId: string
  options: {
    minRange: number
    maxRange: number
    price: number
  }
}) => {
  const url = `${ADMIN_API_URL.PRICE.ROOT}/user-range/${priceId}`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomerResponseType> = await axios.patch(url, options, config)
    return {
      prices: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

export const removeUserRange = async (priceId: string) => {
  const url = `${ADMIN_API_URL.PRICE.ROOT}/user-range/${priceId}`

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomerResponseType> = await axios.delete(url, config)
    return {
      prices: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
}

export const getCustomPrice = createAsyncThunk('admin/getCustomPrice', async (priceId: string) => {
  const url = `${ADMIN_API_URL.PRICE.ROOT}/${priceId}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<PriceResponseType> = await axios.get(url, config)
    return {
      price: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const updateCustomPrice = createAsyncThunk(
  'admin/updateCustomPrice',
  async ({ priceId, options }: CustomRequestPayloadProps) => {
    const url = `${ADMIN_API_URL.PRICE.ROOT}/${priceId}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<PriceResponseType> = await axios.patch(url, options, config)
      return {
        price: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const removeCustomPrice = createAsyncThunk('admin/removeCustomPrice', async (priceId: string) => {
  const url = `${ADMIN_API_URL.PRICE.ROOT}/${priceId}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<PriceResponseType> = await axios.delete(url, config)
    return {
      price: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
