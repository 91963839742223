// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { css, FlattenSimpleInterpolation } from 'styled-components'

const size = {
  xs: '400px', // for small screen mobile
  sm: '640px', // for mobile screen
  md: '768px', // for tablets
  lg: '1024px', // for laptops
  xl: '1280px', // for desktop / monitors
  xxl: '1600px', // for big screens
  xxxl: '1920px', // for big screens
}

type DeviceSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

type DeviceProps = {
  [key in DeviceSizes]: string
}

export const device: DeviceProps = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
}

export const getMediaQuery = (deviceCondition: string, printStyle: () => FlattenSimpleInterpolation) => css`
  @media only screen and ${deviceCondition} {
    ${printStyle()}
  }
`
