import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { DHL_API } from 'Constants/Global.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { DhlListResponseProps, PaginationProps } from 'Types/global.types.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get DHL list
 */
export const getDhl = createAsyncThunk('dhl/list', async ({ pageIndex, pageSize }: PaginationProps) => {
  const token = getAccessToken()
  const url = `${DHL_API.LIST}?page=${pageIndex}&limit=${pageSize}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<DhlListResponseProps> = await axios.get(url, config)
    return {
      dhlList: data?.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Create a new DHL
 */
export const orderDhl = createAsyncThunk('dhl/order', async () => {
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: { url: string } }> = await axios.post(DHL_API.LIST, null, config)
    return data?.data
  } catch (error) {
    handleError(error, true)
  }
})
