import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { DhlListResponse, DhlTypeResponse, PaymentStateType, StateType } from 'Types/admin/admin'
import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get DHL list
 */
export const getDhlList = createAsyncThunk(
  'admin/getDhl',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.DHL.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<DhlListResponse> = await axios.get(url, config)
      return {
        dhlList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update DHL state
 */
export const updateDhlState = createAsyncThunk(
  'admin/updateDhlState',
  async ({ id, options }: { id: string; options: { state: StateType } }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.DHL.ROOT}/${id}/state`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<DhlTypeResponse> = await axios.patch(url, options, config)
      return {
        dhl: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update DHL payment state
 */
export const updateDhlPaymentState = createAsyncThunk(
  'admin/updateDhlPaymentState',
  async ({ id, options }: { id: string; options: { paymentState: PaymentStateType } }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.DHL.ROOT}/${id}/payment-state`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<DhlTypeResponse> = await axios.patch(url, options, config)
      return {
        dhl: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const orderAdminDhl = createAsyncThunk('admin/orderAdminDhl', async (options: { userId: string }) => {
  const token = getAccessToken()
  const url = ADMIN_API_URL.DHL.ROOT
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<DhlTypeResponse> = await axios.post(url, options, config)
    return {
      dhl: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
