import styled from 'styled-components'
import IcoMoonReact from 'icomoon-react'

export const StyledSvgIcon = styled(IcoMoonReact)`
  ${(props) => {
    if (props.layout) {
      return `
        border: 1px solid #D4D4D4;
        padding: 15px;
        background: #ffffff;
        border-radius: 8px;
      `
    }
  }}
`
