import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  bodyClassName: 'toast-body',
  progressClassName: 'progress',
})`
  .toast-item {
    background-color: white;
    display: flex;
    color: black;
    padding: 10px;
    border-radius: 6px;
  }
  .success-message {
    border: 1px solid #80c570;
  }
  .error-message {
    border: 1px solid #e24840;
  }
  .info-message {
    border: 1px solid #5352ed;
  }
  .warning-message {
    border: 1px solid #f5ba5c;
  }
`
