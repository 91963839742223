import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { DashboardDataType, LabelValueProps } from 'Types/admin/admin'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get monthly income
 */
export const getMonthlyIncome = createAsyncThunk(
  'admin/getMonthlyIncome',
  async ({ month, year, type }: { month: number; year: number; type: DashboardDataType }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.INCOME.ROOT}/monthly?month=${month}&year=${year}&type=${type}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: { monthly: number } }> = await axios.get(url, config)
      return {
        income: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get yearly income
 */
export const getYearlyIncome = createAsyncThunk(
  'admin/getYearlyIncome',
  async ({ year, type }: { year: number; type: DashboardDataType }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.INCOME.ROOT}/yearly?year=${year}&type=${type}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: { yearly: number } }> = await axios.get(url, config)
      return {
        income: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Get Diagram data
 */
export const getDiagramByType = createAsyncThunk(
  'admin/getDiagramByType',
  async ({ type, dataType }: { type: 'YEARLY' | 'MONTHLY' | 'WEEKLY' | 'DAILY'; dataType: DashboardDataType }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.INCOME.ROOT}/diagram?type=${type}&dataType=${dataType}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: { data: LabelValueProps[] } }> = await axios.get(url, config)
      return {
        diagram: data.data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
