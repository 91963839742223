import axios, { AxiosError } from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { setAccessToken } from 'Utils/auth.util.ts'

import { AUTH_ERRORS } from 'Constants/Global.constants.ts'

export const decodeToken = (token: string) => jwtDecode(token)

export const setUserToken = (token: string): void => {
  const decoded = jwtDecode<JwtPayload & { iat: number; exp: number }>(token)
  const { exp, iat } = decoded
  const now: Date = new Date(iat * 1000)
  const expires: Date = new Date(exp * 1000)

  const differenceInMs: number = expires.getTime() - now.getTime()
  const days: number = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))

  setAccessToken(token, days)
}

export const handleException = (error: AxiosError | Error) => {
  if (axios.isAxiosError(error)) {
    console.error(AUTH_ERRORS.AXIOS, error.message)
    return error.message
  } else {
    console.error(error)
    return AUTH_ERRORS.UNEXPECTED
  }
}
