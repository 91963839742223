import { closePortal } from 'Stores/portal/portal.slice.ts'
import { useAppDispatch, useAppSelector } from 'Stores/hooks.ts'

import SvgIcon from 'Components/common/SvgIcon'

import {
  StyledPortalModal,
  StyledPortalOverlay,
  StyledPortal,
  StyledModalHeader,
  StyledModalBody,
} from 'Components/common/Portal/Portal.styled.ts'

import ButtonWrapper from 'Components/common/ButtonWrapper'
import Button from 'Components/common/Button'
import { useEffect, useRef } from 'react'

const Portal = () => {
  const dispatch = useAppDispatch()
  const portalRef = useRef<HTMLDivElement | null>(null)
  const { isOpen, title, buttons, component: Content } = useAppSelector((state) => state.portalSlice)

  const handleCloseModal = () => {
    dispatch(closePortal())
  }

  const handleClickOutsidePortal = (event: MouseEvent) => {
    if (portalRef.current && !portalRef.current.contains(event.target as Node)) {
      dispatch(closePortal())
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutsidePortal)
    } else {
      document.removeEventListener('mousedown', handleClickOutsidePortal)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePortal)
    }
  }, [isOpen])

  return (
    isOpen &&
    Content && (
      <StyledPortal className="StyledPortal">
        <StyledPortalOverlay className="StyledPortalOverlay" />
        <StyledPortalModal className="StyledPortalModal" ref={portalRef}>
          <StyledModalHeader className="StyledModalHeader">
            <div className="close-icon" onClick={handleCloseModal}>
              <SvgIcon icon="close" size={14} />
            </div>
            <div>{title}</div>
          </StyledModalHeader>
          <StyledModalBody className="StyledModalBody">
            {/* @ts-ignore */}
            <Content />
          </StyledModalBody>
          {buttons && (
            <ButtonWrapper>
              {buttons.map((button, i) => (
                <Button key={`button-${i}`} buttonType={button.type} onClick={button.handler}>
                  {button.label}
                </Button>
              ))}
            </ButtonWrapper>
          )}
        </StyledPortalModal>
      </StyledPortal>
    )
  )
}

export default Portal
