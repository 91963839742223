import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CertificateType, PaginationProps } from 'Types/global.types.ts'
import { CommentType, CustomFileType, DocumentGroup } from 'Types/admin/admin'

import {
  getOrderListByUser,
  getOrderMonthlyStatistics,
  getOrderGeneralStatistics,
  assignAdminToOrder,
  assignReviewerToOrder,
  getOrderList,
  getAdminOrderDetails,
  getOrderCustomFiles,
  uploadOrderCarDocument,
  uploadOrderCustomFile,
  getOrderComments,
  postOrderComment,
  updateOrderState,
  updateOrderPrintDate,
  updateOrderEur1Id,
  updateOrderPaymentState,
  updateOrderDetails,
  generateOrderEur1,
  uploadEoEur1Document,
} from 'Api/admin/admin.orders.service.ts'
import { uploadCustomerDocument } from 'Api/admin/admin.customers.service.ts'
import { updateAdminBuyer } from 'Api/admin/admin.buyer.service.ts'
import { setSellerToOrder, updateAdminSeller } from 'Api/admin/admin.seller.service.ts'
import { cancelFacture } from 'Api/admin/admin.invoices.service.ts'

interface InitialStateProps {
  orderList: CertificateType[]
  initialOrderList: CertificateType[]
  orderListTotal: number
  orderListLoading: boolean
  customFiles: CustomFileType[]
  carCustomFiles: CustomFileType[]
  userCustomFiles: CustomFileType[]
  comments: CommentType[]
  totalComments: number
  pageIndexComments: number
  pageSizeComments: number
  commentPagesNumber: number
  orderListByUser: CertificateType[]
  initialOrderListByUser: CertificateType[]
  statistics: {
    monthly: number
    general: number
  }
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  sort: 'COMMAND' | 'CREATED_AT'
  order: 'ASC' | 'DESC'
  param: string
  orderDetails: CertificateType
  customsStatus: string
}

const initialState: InitialStateProps = {
  orderList: [],
  initialOrderList: [],
  orderListTotal: 0,
  orderListLoading: false,
  customFiles: [],
  carCustomFiles: [],
  userCustomFiles: [],
  comments: [],
  totalComments: 0,
  pageIndexComments: 0,
  pageSizeComments: 10,
  commentPagesNumber: 0,
  orderListByUser: [],
  initialOrderListByUser: [],
  statistics: {
    monthly: 0,
    general: 0,
  },
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  sort: 'CREATED_AT',
  order: 'DESC',
  param: '',
  orderDetails: null,
  customsStatus: '',
}

const orderListSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {
    setCommentsPageIndex: (state) => {
      const nextPageIndex = state.pageIndexComments + 1
      if (nextPageIndex < state.commentPagesNumber) {
        state.pageIndexComments += 1
      }
    },
    setOrderListPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setOrderListPageSize: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASC' | 'DESC'>) => {
      if (action.payload) {
        state.order = action.payload
      }
    },
    setSort: (state, action: PayloadAction<'COMMAND' | 'CREATED_AT'>) => {
      if (action.payload) {
        state.sort = action.payload
      }
    },
    resetOrderDetailsState: (state) => {
      state.orderDetails = null
      state.comments = []
      state.totalComments = 0
      state.pageIndexComments = 0
      state.pageSizeComments = 0
    },
    resetOrderList: () => {
      return initialState
    },
    setCustomsStatus: (state, action) => {
      if (action.payload) {
        state.customsStatus = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderListByUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderListByUser = action.payload.orderList
          state.initialOrderListByUser = action.payload.orderList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getOrderListByUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getOrderListByUser.rejected, (state) => {
        state.loading = false
      })
      .addCase(getOrderList.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = action.payload.orderList
          state.orderListTotal = action.payload.total
        }
        state.orderListLoading = false
      })
      .addCase(getOrderList.pending, (state) => {
        state.orderListLoading = true
      })
      .addCase(getOrderList.rejected, (state) => {
        state.orderListLoading = false
      })
      .addCase(getOrderMonthlyStatistics.fulfilled, (state, action) => {
        state.statistics.monthly = action.payload.monthlyStatistics
        state.loading = false
      })
      .addCase(getOrderMonthlyStatistics.pending, (state) => {
        state.loading = true
      })
      .addCase(getOrderMonthlyStatistics.rejected, (state) => {
        state.loading = false
      })
      .addCase(getOrderGeneralStatistics.fulfilled, (state, action) => {
        if (action.payload) {
          state.statistics.general = action.payload.generalStatistics
          state.loading = false
        }
      })
      .addCase(getOrderGeneralStatistics.pending, (state) => {
        state.loading = true
      })
      .addCase(getOrderGeneralStatistics.rejected, (state) => {
        state.loading = false
      })
      .addCase(assignAdminToOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.adminOrder.id) {
              return action.payload.adminOrder
            } else {
              return order
            }
          })

          if (state.orderDetails && state.orderDetails?.id === action.payload.adminOrder.id) {
            state.orderDetails = action.payload.adminOrder
          }
        }
      })
      .addCase(assignReviewerToOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.adminOrder.id) {
              return action.payload.adminOrder
            } else {
              return order
            }
          })

          if (state.orderDetails && state.orderDetails?.id === action.payload.adminOrder.id) {
            state.orderDetails = action.payload.adminOrder
          }
        }
      })
      .addCase(getAdminOrderDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.orderDetails
        }
      })
      .addCase(getOrderCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          const { group } = action.payload
          switch (group as DocumentGroup) {
            case 'CERTIFICATE_DOCS': {
              state.customFiles = action.payload.customFiles
              break
            }
            case 'USER_DOCS': {
              state.userCustomFiles = action.payload.customFiles
              break
            }
            case 'CAR_DOCS': {
              state.carCustomFiles = action.payload.customFiles
              break
            }
          }
        }
      })
      .addCase(uploadEoEur1Document.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.certificate
        }
      })
      .addCase(uploadOrderCarDocument.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.certificate
        }
      })
      .addCase(uploadOrderCustomFile.fulfilled, (state, action) => {
        if (action.payload?.customFile.group) {
          const group: DocumentGroup = action.payload.customFile.group

          switch (group) {
            case 'CERTIFICATE_DOCS': {
              state.customFiles.unshift(action.payload.customFile)
              break
            }
            case 'CAR_DOCS': {
              state.carCustomFiles.unshift(action.payload.customFile)
              break
            }
            case 'USER_DOCS': {
              state.userCustomFiles.unshift(action.payload.customFile)
              break
            }
          }
        }
      })
      .addCase(uploadCustomerDocument.fulfilled, (state, action) => {
        if (action.payload && state.orderDetails?.user?.id === action.payload.customer.id) {
          state.orderDetails.user = action.payload.customer
        }
      })
      .addCase(getOrderComments.fulfilled, (state, action) => {
        if (action.payload) {
          state.commentPagesNumber = action.payload.total / state.pageSizeComments
          state.comments.unshift(...action.payload.comments)
          state.totalComments += action.payload.total
        }
      })
      .addCase(postOrderComment.fulfilled, (state, action) => {
        if (action.payload) {
          state.comments.push(action.payload.comment)
        }
      })
      .addCase(updateOrderState.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.certificate?.id) {
              return {
                ...order,
                state: action.payload.certificate.state,
              }
            } else {
              return order
            }
          })

          if (state.orderDetails && state.orderDetails?.id === action.payload.certificate?.id) {
            state.orderDetails = {
              ...state.orderDetails,
              state: action.payload.certificate.state,
            }
          }
        }
      })
      .addCase(updateAdminBuyer.fulfilled, (state, action) => {
        if (action.payload) {
          if (state.orderDetails.buyer.id === action.payload.buyer.id) {
            state.orderDetails.buyer = action.payload.buyer
          }
        }
      })
      .addCase(updateAdminSeller.fulfilled, (state, action) => {
        if (action.payload) {
          if (state.orderDetails?.seller?.id === action.payload.seller.id) {
            state.orderDetails.seller = action.payload.seller
          }
        }
      })
      .addCase(updateOrderEur1Id.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.order.id) {
              return action.payload.order
            } else {
              return order
            }
          })

          if (state.orderDetails && state.orderDetails?.id === action.payload.order.id) {
            state.orderDetails = action.payload.order
          }
        }
      })
      .addCase(updateOrderPrintDate.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.order.id) {
              return action.payload.order
            } else {
              return order
            }
          })

          if (state.orderDetails && state.orderDetails?.id === action.payload.order.id) {
            state.orderDetails = action.payload.order
          }
        }
      })
      .addCase(updateOrderPaymentState.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.order
        }
      })
      .addCase(updateOrderDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.certificate

          state.orderList = state.orderList.map((order) => {
            if (order.id === action.payload.certificate.id) {
              return action.payload.certificate
            } else {
              return order
            }
          })
        }
      })
      .addCase(generateOrderEur1.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.order
        }
      })
      .addCase(setSellerToOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetails = action.payload.order
        }
      })
      .addCase(cancelFacture.fulfilled, (state, action) => {
        if (action.payload && action.payload.entityType === 'CERTIFICATE') {
          state.orderDetails.facture = action.payload.facture
          state.orderDetails.paymentState = 'CANCELED'
        }
      })
  },
})

const { actions } = orderListSlice
export const {
  setOrderListPageSize,
  setSort,
  setOrder,
  setParam,
  resetOrderDetailsState,
  setCommentsPageIndex,
  setOrderListPageIndex,
  setCustomsStatus,
  resetOrderList,
} = actions
export default orderListSlice.reducer
