import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationProps, ShippingAddressSliceProps } from 'Types/global.types.ts'

import { setUserPrimaryShippingAddress } from 'Api/user.service.ts'
import {
  addShippingAddress, getPrimaryShippingAddress,
  getShippingAddress,
  removeShippingAddress,
  updateShippingAddress
} from 'Api/shipping-address.service.ts'

const initialState: ShippingAddressSliceProps = {
  addressList: [],
  primaryShippingAddress: null,
  total: 0,
  loading: false,
  pageIndex: 0,
  pageSize: 5,
}

const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState,
  reducers: {
    setShippingAddressPageSize: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    resetShippingAddressState: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.addressList = action.payload.shippingAddressList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getPrimaryShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.primaryShippingAddress = action.payload.primaryShippingAddress
        }
      })
      .addCase(getShippingAddress.pending, (state) => {
        state.loading = true
      })
      .addCase(getShippingAddress.rejected, (state) => {
        state.loading = false
      })
      .addCase(addShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.addressList.unshift(action.payload.shippingAddress)
        }
      })
      .addCase(updateShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
            state.addressList = state.addressList.map((address) => {
              if (address.id === action.payload.shippingAddress.id) {
                return action.payload.shippingAddress
              }
              return address
            })
          }
      })
      .addCase(removeShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.addressList = state.addressList.filter((address) => address.id !== action.payload.id)
        }
      })
      .addCase(setUserPrimaryShippingAddress.fulfilled, (state, action) => {
        if (action.payload) {
          state.addressList = state.addressList.map((address) => {
            if (address.id === action.payload.shippingAddressId) {
              return {
                ...address,
                isPrimary: true,
              }
            }
            return {
              ...address,
              isPrimary: false,
            }
          })
        }
      })
  },
})

const { actions } = shippingAddressSlice
export const { setShippingAddressPageSize, resetShippingAddressState } = actions
export default shippingAddressSlice.reducer
