import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StoragePlace } from 'Types/global.types'
import { PaginationProps } from 'Types/global.types.ts'

import {
  addStoragePlace,
  getStoragePlacesList,
  removeStoragePlace,
  updateStoragePlace,
} from 'Api/admin/admin.storageplaces.service.ts'

interface InitialStateProps {
  storagePlacesList: StoragePlace[]
  total: number
  loading: boolean
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  storagePlacesList: [],
  total: 0,
  loading: false,
  pageIndex: 0,
  pageSize: 50,
}

const storagePlacesListSlice = createSlice({
  name: 'storagePlacesList',
  initialState,
  reducers: {
    setStoragePlacesPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoragePlacesList.fulfilled, (state, action) => {
        if (action.payload) {
          state.storagePlacesList = action.payload.storagePlacesList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getStoragePlacesList.pending, (state) => {
        state.loading = true
      })
      .addCase(getStoragePlacesList.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeStoragePlace.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.storagePlacesList = state.storagePlacesList.filter(
            (storagePlace) => storagePlace.id !== action.payload.storagePlaceId
          )
        }
      })
      .addCase(addStoragePlace.fulfilled, (state, action) => {
        if (action.payload) {
          state.storagePlacesList.unshift(action.payload.storagePlace)
        }
      })
      .addCase(updateStoragePlace.fulfilled, (state, action) => {
        state.storagePlacesList = state.storagePlacesList.map((storagePlace) => {
          if (storagePlace.id === action.payload.storagePlace.id) {
            return action.payload.storagePlace
          }
          return storagePlace
        })
      })
  },
})

const { actions } = storagePlacesListSlice
export const { setStoragePlacesPageIndex } = actions
export default storagePlacesListSlice.reducer
