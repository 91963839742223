import { createSlice } from '@reduxjs/toolkit'
import { getLanguageList } from 'Constants/Global.constants.ts'

const languageList = getLanguageList(true)
const defaultLanguage = languageList[0]

const initialState = {
  defaultLanguage: defaultLanguage,
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.defaultLanguage = action.payload
    },
  },
})

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer
