import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { NOTIFICATION_API, NOTIFICATION_BASE_API_URL } from 'Constants/Global.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import {
  GetNotificationsResponse,
  MarkAsReadResponse,
  NotificationProps,
  ReadAllNotificationsResponse,
  UnreadCountNotificationsResponse,
} from 'Types/notifications.types.ts'
import { handleError } from 'Helpers/errors.helper.ts'
import { PaginationProps } from 'Types/global.types.ts'

const getHeaders = () => {
  const token = getAccessToken()
  return {
    headers: {
      Authorization: token,
    },
  }
}

const NOTIFICATION_TYPES = {
  GET_NOTIFICATIONS: 'notifications/getNotifications',
  READ_ALL: 'notifications/readAll',
  MARK_AS_READ: 'notifications/markAsRead',
  UNREAD_COUNT: 'notifications/unreadCount',
}

export const getNotifications = createAsyncThunk(
  NOTIFICATION_TYPES.GET_NOTIFICATIONS,
  async ({ pageIndex, pageSize }: PaginationProps) => {
    const config = getHeaders()
    const url = `${NOTIFICATION_BASE_API_URL}?page=${pageIndex}&limit=${pageSize}&only-unread=true`

    try {
      const { data }: AxiosResponse<GetNotificationsResponse> = await axios.get(url, config)
      return {
        notifications: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const readAll = createAsyncThunk(NOTIFICATION_TYPES.READ_ALL, async (options) => {
  const url = NOTIFICATION_API.READ_ALL
  const config = getHeaders()

  try {
    const { data }: AxiosResponse<ReadAllNotificationsResponse> = await axios.patch(url, options, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const markAsRead = createAsyncThunk(NOTIFICATION_TYPES.MARK_AS_READ, async ({ id }: NotificationProps) => {
  const url = `${NOTIFICATION_API.ROOT}/${id}/read`
  const config = getHeaders()

  try {
    const { data }: AxiosResponse<MarkAsReadResponse> = await axios.patch(url, null, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

export const getUnreadCount = createAsyncThunk(NOTIFICATION_TYPES.UNREAD_COUNT, async () => {
  const url = NOTIFICATION_API.UNREAD_COUNT
  const config = getHeaders()

  try {
    const { data }: AxiosResponse<UnreadCountNotificationsResponse> = await axios.get(url, config)
    return data.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})
