import { TFunction } from 'i18next'

const API_URL: string = import.meta.env.VITE_API_URL as string

export const ADMIN_API_ROOT = `${API_URL}/admin`

export const ADMIN_API_URL = {
  DOWNLOAD: `${ADMIN_API_ROOT}/download`,
  AUTH: {
    SIGNIN: `${ADMIN_API_ROOT}/auth/signin`,
    SIGNUP: `${ADMIN_API_ROOT}/auth/signup`,
    VALIDATE_OTP: `${ADMIN_API_ROOT}/auth/validate-otp`,
  },
  PURCHASE: {
    ROOT: `${ADMIN_API_ROOT}/purchase`,
    LIST: `${ADMIN_API_ROOT}/purchase/search`,
  },
  ORDER: {
    ROOT: `${ADMIN_API_ROOT}/certificate`,
  },
  CUSTOMER: {
    ROOT: `${ADMIN_API_ROOT}/user`,
  },
  CUSTOMS_CROSSING: {
    ROOT: `${ADMIN_API_ROOT}/customs-crossing`,
  },
  CUSTOMS_VARIABLE: {
    ROOT: `${ADMIN_API_ROOT}/customs-variable`,
  },
  BUYER: {
    ROOT: `${ADMIN_API_ROOT}/buyer`,
  },
  SELLER: {
    ROOT: `${ADMIN_API_ROOT}/seller`,
    SEARCH: `${ADMIN_API_ROOT}/seller/search`,
    EORI: `${ADMIN_API_ROOT}/seller/eori`,
  },
  EUR1_COUNTER: {
    ROOT: `${ADMIN_API_ROOT}/eur1Counter`,
  },
  BLACKLIST: {
    ROOT: `${ADMIN_API_ROOT}/blacklist`,
  },
  FACTURE: {
    ROOT: `${ADMIN_API_ROOT}/facture`,
    SEARCH: `${ADMIN_API_ROOT}/facture/search`,
    MONTHLY_DOWNLOAD: `${ADMIN_API_ROOT}/facture/monthly-download`,
  },
  INCOME: {
    ROOT: `${ADMIN_API_ROOT}/income`,
  },
  STORAGE_PLACE: {
    ROOT: `${ADMIN_API_ROOT}/storage-place`,
  },
  DHL: {
    ROOT: `${ADMIN_API_ROOT}/dhl`,
  },
  USER: {
    ROOT: `${ADMIN_API_ROOT}/user`,
  },
  CUSTOMS: {
    CUSTOMS_TRANSACTION: `${ADMIN_API_ROOT}/customs-transaction`,
  },
  PRICE: {
    ROOT: `${ADMIN_API_ROOT}/price`,
  },
  DEBLOCAGE: {
    ROOT: `${ADMIN_API_ROOT}/deblocage`,
  },
  LOG: {
    ROOT: `${ADMIN_API_ROOT}/log`,
    CERTIFICATE: `${ADMIN_API_ROOT}/log/certificate`,
    PURCHASE: `${ADMIN_API_ROOT}/log/purchase`,
  },
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getTranslateMonthNames = (t: TFunction) => [
  t('translation:common.months.january'),
  t('translation:common.months.february'),
  t('translation:common.months.march'),
  t('translation:common.months.april'),
  t('translation:common.months.may'),
  t('translation:common.months.June'),
  t('translation:common.months.july'),
  t('translation:common.months.august'),
  t('translation:common.months.september'),
  t('translation:common.months.october'),
  t('translation:common.months.november'),
  t('translation:common.months.december'),
]

export const getVehicleTypeOptions = (t: TFunction) => [
  { value: 'TRUCK', label: t('translation:common.label.truck') },
  { value: 'VEHICLE', label: t('translation:common.label.vehicle') },
]

export const getTranslate_default_reserved_names = (t: TFunction) => [
  t('translation:common.label.gray_card'),
  t('translation:common.label.invoice'),
  t('translation:form.placeholder.delivery_note'),
  t('translation:form.placeholder.driving_licence'),
  t('translation:form.placeholder.business_registry'),
  t('translation:common.label.swift'),
]

export const customsTransactionStatuses = {
  creationDauValide: 'CREATION_DAU_VALIDE',
  ValidationDauEnAttente: 'VALIDATION_DAT_EN_ATTENTE',
  AnnulationDau: 'ANNULATION_DAU',
  InvalidationDau: 'INVALIDATION_DAU',
  ValidationDauAnticipe: 'VALIDATION_DAT_ANTICIPE',
}

export const mapCustomsTransactionStatuses = {
  CREATION_DAU_VALIDE: 'Creation DAU Valide',
  VALIDATION_DAU_EN_ATTENTE: 'Validation DAU en Attente',
  ANNULATION_DAU: 'Annulation DAU',
  INVALIDATION_DAU: 'Invalidation DAU',
  RECTIFICATION_DAU: 'Rectification DAU',
  VALID_RECTIFICATION: 'Valid Rectification',
  ANNUAL_RECTIFICATION: 'Annual Rectification',
  VALIDATION_DAT_ANTICIPE: 'Validation DAU Anticipe',
  MODIFICATION_PAIEMENT_DAU: 'Modification Paiement DAU',
  CREATION_DAU_SECOURS: 'Creation DAU Secours',
  CREATION_DAU_ANTICIPE: 'Creation DAU Anticipe',
  MODIFICATION_DAU: 'Modification DAU',
}

export const customsTransactionStatusOptions = [
  {
    label: 'Creation Dau Valide',
    value: 'CREATION_DAU_VALIDE',
  },
  {
    label: 'Validation Dau En Attente',
    value: 'VALIDATION_DAU_EN_ATTENTE',
  },
  {
    label: 'Annulation Dau',
    value: 'ANNULATION_DAU',
  },
  {
    label: 'Invalidation Dau',
    value: 'INVALIDATION_DAU',
  },
  {
    label: 'Rectification dau',
    value: 'RECTIFICATION_DAU',
  },
  {
    label: 'Valid rectification',
    value: 'VALID_RECTIFICATION',
  },
  {
    label: 'Annual rectification',
    value: 'ANNUAL_RECTIFICATION',
  },
  {
    label: 'Creation dau anticipe',
    value: 'CREATION_DAU_ANTICIPE',
  },
  {
    label: 'Validation Dau Anticipe',
    value: 'VALIDATION_DAT_ANTICIPE',
  },
  {
    label: 'Modification paiement dau',
    value: 'MODIFICATION_PAIEMENT_DAU',
  },
  {
    label: 'Creation dau secours',
    value: 'CREATION_DAU_SECOURS',
  },
  {
    label: 'Modification dau',
    value: 'MODIFICATION_DAU',
  },
]

export const getWorkingStatusTranslation = (t: TFunction) => ({
  NOT_PROCESSED: t('translation:common.status.not_processed'),
  IN_PROGRESS: t('translation:common.status.in_progress'),
  IN_REVIEW: t('translation:common.status.in_review'),
  REVIEWED: t('translation:common.status.reviewed'),
  PROCESSED: t('translation:common.status.processed'),
})

export const vehicleFields = [
  'brand',
  'model',
  'firstRegistrationAt',
  'year',
  'registration',
  'vin',
  'weight',
  'numberOfKm',
  'invoiceNumber',
  'price',
  'nomenclature',
  'meansOfTransport',
  'storagePlace',
  'euExitPlace',
  'vehicleLicenceNumber',
]
