import styled from 'styled-components'
import { getMediaQuery, device } from 'Styles/Breakpoints.ts'

export const StyledDrawer = styled.div<{ drawerWidth: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 1.2rem;
  bottom: 1.2rem;
  right: 0;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.colors.grays.white};
  width: ${(props) => (props.drawerWidth ? `${props.drawerWidth}rem` : '25rem')};
  height: auto;
  overflow: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  padding: 30px 45px;
  z-index: 1000;

  &.open {
    border: 1px solid ${(props) => props.theme.colors.grays.gray300};
    box-shadow: -12px 3px 20px rgba(0, 0, 0, 0.07);
    transform: translateX(0);
    right: 1.2rem;

    ${getMediaQuery(
      device.sm,
      () => `
        inset: 1rem;
        width: auto;
  `
    )}
  }
`

export const StyledDrawerHeader = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .drawer-close-btn {
    display: flex;
    cursor: pointer;
  }

  .drawer-subtitle {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grays.gray800};
  }
`

export const StyledDrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  //position: relative;
`

export const StyledPreventOverlay = styled.div`
  background-color: transparent;
  position: fixed;
  inset: 0;
  z-index: 1;
`
