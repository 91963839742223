import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { BlacklistAllResponseType, BlacklistResponseType, BlacklistType } from 'Types/admin/admin'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get blacklist items
 */
export const getBlackList = createAsyncThunk(
  'admin/getBlackList',
  async ({ pageIndex, pageSize, param }: { pageIndex: number; pageSize: number; param: string }) => {
    let url = `${ADMIN_API_URL.BLACKLIST.ROOT}`

    const queryParams = []

    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<BlacklistAllResponseType> = await axios.get(url, config)
      return {
        blacklistAll: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Create a new Blacklist item
 */
export const createBlacklist = createAsyncThunk('admin/createBlacklist', async (options: BlacklistType) => {
  const token = getAccessToken()
  const url = ADMIN_API_URL.BLACKLIST.ROOT
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<BlacklistResponseType> = await axios.post(url, options, config)
    return {
      blacklist: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Update Blacklist item
 */
export const updateBlacklist = createAsyncThunk(
  'admin/updateBlacklist',
  async ({ id, options }: { id: string; options: BlacklistType }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.BLACKLIST.ROOT}/${id}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<BlacklistResponseType> = await axios.patch(url, options, config)
      return {
        blacklist: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Remove blacklist item
 */
export const removeBlacklist = createAsyncThunk('admin/removeBlacklist', async (id: string) => {
  const url = `${ADMIN_API_URL.BLACKLIST.ROOT}/${id}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: boolean }> = await axios.delete(url, config)
    return {
      id,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error)
  }
})
