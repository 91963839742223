import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

import App from './App.tsx'

import './i18n.ts'

import { store } from './stores/store.ts'
import { theme } from './styles/Theme.ts'
import { GlobalStyles } from './styles/Global.ts'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
)
