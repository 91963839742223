import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { remove as _remove } from 'lodash'

import { NotificationProps } from 'Types/notifications.types.ts'
import { getNotifications, getUnreadCount, markAsRead, readAll } from 'Api/notification.service.ts'
import { PaginationProps } from 'Types/global.types.ts'

interface NotificationState {
  notificationList: Array<NotificationProps>
  unreadCount: number
  total: number
  pageIndex: number
  pageSize: number
  loading: boolean
}

const initialState: NotificationState = {
  notificationList: [],
  unreadCount: 0,
  total: 0,
  pageIndex: 0,
  pageSize: 5,
  loading: false,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        if (action.payload) {
          state.notificationList = action.payload.notifications
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getNotifications.pending, (state) => {
        state.loading = true
      })
      .addCase(getNotifications.rejected, (state) => {
        state.loading = false
      })
      .addCase(readAll.fulfilled, (state, action) => {
        if (action.payload) {
          state.unreadCount = 0
          state.notificationList = []
        }
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        if (action.payload) {
          state.notificationList = state.notificationList.filter(notification => notification.id !== action.payload.id)
          if (state.unreadCount > 0) {
            state.unreadCount -= 1
          }
        }
      })
      .addCase(getUnreadCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.unreadCount = action.payload.unread
        }
      })
  },
})

const { actions } = notificationsSlice
export const { setNotificationPageIndex } = actions

export default notificationsSlice.reducer
