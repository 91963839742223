import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AuctionHouseProps } from 'Types/global.types.ts'
import { API_URL } from 'Constants/Global.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'
import { AuctionHouseType } from 'Types/auctionHouse'
import { AuctionHouseApiType } from 'Schemas/auctionHouse.types.ts'

type AuctionHouseRequestProps = {
  param?: string
}

export const loadAsyncAuctionHouseList = async () => {
  const url = `${API_URL}/auction-house`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AuctionHouseType[]; total: number }> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Fetch auction house list
 */
export const searchAuctionHouseList = async ({ param }: AuctionHouseRequestProps) => {
  if (param.length >= 3) {
    const url = `${API_URL}/auction-house/search?param=${param}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AuctionHouseType[]; total: number }> = await axios.get(url, config)

      return {
        list: data?.data || [],
        total: data?.total || 0,
      }
    } catch (error) {
      handleError(error, true)
    }
  } else {
    return {
      list: [],
      total: 0,
    }
  }
}

/**
 * Load auction house list
 */
export const getAuctionHouseList = createAsyncThunk('admin/getAuctionHouse', async () => {
  const url = `${API_URL}/auction-house`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AuctionHouseType[]; total: number }> = await axios.get(url, config)
    return {
      auctionHouseList: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const searchAuctionHouse = createAsyncThunk('admin/searchAuctionHouse', async (param: string) => {
  const url = `${API_URL}/auction-house/search?param=${param}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AuctionHouseProps[] }> = await axios.get(url, config)
    return {
      auctionHouseList: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const addAuctionHouse = createAsyncThunk('admin/addAuctionHouse', async (params: AuctionHouseApiType) => {
  const url = `${API_URL}/auction-house`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<{ data: AuctionHouseApiType }> = await axios.post(url, params, config)
    return {
      auctionHouse: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})
