import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEmpty as _isEmpty } from 'lodash'

import { BuyerSliceProps, BuyerType, PaginationProps } from 'Types/global.types.ts'
import { addBuyer, getBuyer, removeBuyer, searchBuyer, updateBuyer } from 'Api/buyer.service.ts'

const initialState: BuyerSliceProps = {
  initialBuyers: [],
  buyers: [],
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 10,
}

const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    setBuyerPageSize: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBuyer.fulfilled, (state, action: PayloadAction<BuyerType>) => {
        if (action.payload) {
          state.buyers.unshift(action.payload)
          state.initialBuyers.unshift(action.payload)
          state.total += 1
        }
      })
      .addCase(getBuyer.fulfilled, (state, action) => {
        if (action.payload) {
          // @ts-ignore
          state.buyers = action.payload.buyers
          state.total = action.payload.total
          // @ts-ignore
          state.initialBuyers = action.payload.buyers
        }
        state.loading = false
      })
      .addCase(getBuyer.pending, (state) => {
        state.loading = true
      })
      .addCase(getBuyer.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeBuyer.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyers = state.buyers.filter((buyer) => buyer.id !== action.payload.id)
          state.total -= 1
        }
      })
      .addCase(updateBuyer.fulfilled, (state, action) => {
        if (action.payload) {
          const mutableBuyers = state.buyers.map((buyer) => {
            if (buyer.id === action.payload.id) {
              return action.payload
            }
            return buyer
          })
          state.buyers = mutableBuyers
          state.initialBuyers = mutableBuyers
        }
      })
      .addCase(searchBuyer.fulfilled, (state, action) => {
        if (!_isEmpty(action.payload)) {
          // @ts-ignore
          state.buyers = action.payload.buyers
        } else {
          state.buyers = [...state.initialBuyers]
        }
        state.loading = false
      })
      .addCase(searchBuyer.pending, (state) => {
        state.loading = true
      })
      .addCase(searchBuyer.rejected, (state) => {
        state.loading = false
      })
  },
})

const { actions } = buyerSlice
export const { setBuyerPageSize } = actions
export default buyerSlice.reducer
