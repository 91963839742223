import { ReactElement } from 'react'

import { ButtonTypeNames } from 'Types/button.types.ts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialPortalStateProps = {
  isOpen: boolean
  component: ReactElement
  title: string
  buttons?: { type: ButtonTypeNames; label: string; handler: () => void }[]
}

const initialState: InitialPortalStateProps = {
  isOpen: false,
  component: null,
  title: '',
  buttons: [],
}

export const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    openPortal: (
      state,
      action: PayloadAction<{
        component: JSX.Element
        title: string
        buttons: { type: ButtonTypeNames; label: string; handler: () => void }[]
      }>
    ) => {
      if (action.payload) {
        const { component, title, buttons } = action.payload
        state.isOpen = true
        state.title = title
        state.component = component
        if (buttons) {
          state.buttons = buttons
        }
      }
    },
    closePortal: (state) => {
      state.isOpen = false
      state.component = null
    },
  },
})

export const { openPortal, closePortal } = portalSlice.actions
export default portalSlice.reducer
