import styled, { createGlobalStyle, DefaultTheme, keyframes } from 'styled-components'
import { getMediaQuery, device } from 'Styles/Breakpoints.ts'

export const GlobalStyles = createGlobalStyle`
  :root {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    background-color: ${(props) => props.theme.colors.grays.gray100};
    min-width: 100vw;
    height: 100vh;
    font-size: 14px;
    color: ${(props) => props.theme.colors.grays.gray800};
    padding-top: 6rem;
    line-height: 1.5rem;
    font-family: Inter, sans-serif;
  }
  
  //* {
  //  box-sizing: border-box;
  //}
  
  h1 {
    font-size: 4.5rem;
    line-height: 4.8rem;
  }
  h2 {
    font-size: 3.5rem;
    line-height: 3.75rem;
  }
  h3 {
    font-size: 2.62rem;
    line-height: 2.8rem;
    margin: 0.75rem 0;
  }
  h4 {
    font-size: 2rem;
    line-height: 2.25rem;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: 0.75rem 0;
  }
  h6 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0.75rem 0;
    font-weight: 600;
  }
  
  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  
  a {
    color: ${(props) => props.theme.colors.primary.default};
    text-decoration: none
  }
  
  ul {
    margin: 0;
    padding-inline-start: 25px;
  }
  
  /* Colors */
  .primary-default {
    color: #5352ED;
  }
  .primary-light {
    color: #EEEEFD;
  }
  .primary-medium {
    color: #CBCBFA;
  }
  .primary-strong {
    color: #9897F4;
  }
  .primary-dark {
    color: #3837B4;
  }
  
  .color-medium {
    color: #4C5363;
  }

  .gray-100 {
    color: ${(props) => props.theme.colors.grays.gray100};
  }
  .gray-200 {
    color: ${(props) => props.theme.colors.grays.gray200};
  }
  .gray-300 {
    color: ${(props) => props.theme.colors.grays.gray300};
  }
  .gray-400 {
    color: ${(props) => props.theme.colors.grays.gray400};
  }
  .gray-500 {
    color: ${(props) => props.theme.colors.grays.gray500};
  }
  .gray-600 {
    color: ${(props) => props.theme.colors.grays.gray600};
  }
  .gray-700 {
    color: ${(props) => props.theme.colors.grays.gray700};
  }
  .gray-800 {
    color: ${(props) => props.theme.colors.grays.gray800};
  }
  .gray-900 {
    color: ${(props) => props.theme.colors.grays.gray900};
  }
  
  .link-color {
    color: ${(props) => props.theme.colors.primary.default};
    line-height: 1rem;
    cursor: pointer;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  /* Font sizes */
  .text-size-10 {
    font-size: 0.625rem;
    line-height: 1.5rem;
  }
  .text-size-12 {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  .text-size-14 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .text-size-16 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-size-18 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .text-size-20 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .text-size-24 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .text-size-26 {
    font-size: 1.625rem;
    line-height: 1.938rem;
  }
  
  /* Text style */
  .text-weight-300 {
    font-weight: 300;
  }
  .text-weight-400 {
    font-weight: 400;
  }
  .text-weight-500 {
    font-weight: 500;
  }
  .text-weight-600 {
    font-weight: 600;
  }
  .text-weight-700 {
    font-weight: 700;
  }
  .text-weight-800 {
    font-weight: 800;
  }
  .text-weight-900 {
    font-weight: 900;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-default {
    cursor: default;
  }
  
  .strikethrough {
    text-decoration: line-through;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .flex-inline {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
  
  .flex-center {
    display: flex;
    align-items: center;
  }
  
  .flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .no-wrap {
    white-space: nowrap;
  }
  
  /* Flex helpers classes */
  .flex-gap-025 {
    gap: 0.25rem;
  }

  .flex-gap-05 {
    gap: 0.5rem;
  }

  .flex-gap-1 {
    gap: 1rem;
  }

  .flex-gap-1-5 {
    gap: 1.5rem;
  }
  
  .flex-gap-2 {
    gap: 2rem;
  }
  
  .mb-1 {
    margin-bottom: 1rem;
  }

  .mb-1_5 {
    margin-bottom: 1.5rem;
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .mb-2_5 {
    margin-bottom: 2.5rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  .mt-1_5 {
    margin-top: 1.5rem;
  }

  .mt-2 {
    margin-top: 2rem;
  }

  .mt-2_5 {
    margin-top: 2.5rem;
  }

  .mt-3 {
    margin-top: 3rem;
  }

  .w-full {
    width: 100%;
  }
  
  table {
    width: 100%;
  }

  .ellipsis-200 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  
  /* Element structure */
  .half-children {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1rem;

    > div {
      width: 50%;
    }
  }
  
  /* Datepicker */
  .datepicker-container {
    .react-datepicker,
    .react-datepicker__header {
      background-color: white;
      border: none;
    }
    .react-datepicker__current-month {
      margin: 1rem 0;
      font-weight: 400;
      font-size: 12px;
    }

    .react-datepicker {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
      font-size: 11px;
      font-weight: 400;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      border: none;
      outline: none;
      cursor: pointer;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      background-color: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='%235352ED' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 10px;
      border-radius: 2px;
      margin-right: 2rem;
      padding: 1rem 2rem 1rem 1rem;
      font-size: 12px;
    }

    .react-datepicker__navigation {
      top: 20px;

      > span:before {
        border-color: ${(props) => props.theme.colors.primary.default};
      }
    }

    .react-datepicker__day--selected {
      border-radius: 2px;
      background-color: ${(props) => props.theme.colors.primary.default};
      &:hover {
        background-color: ${(props) => props.theme.colors.primary.default};
      }
    }

    .react-datepicker__current-month--hasYearDropdown,
    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
      width: 50%;
      display: inline-block;
    }
  }
`

export const StyledBox = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
`

export const StyledInnerDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  form {
    margin: auto;
  }
`

/* FORM Styled Components */
export const StyledGrayFormBox = styled.div`
  background-color: #f5f5f9;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  border-radius: 0.188rem;
  margin: 0.45rem 0;
  gap: 0.75rem;
`

export const StyledGrayFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  ${getMediaQuery(
    device.md,
    () => `
    flex-direction: column;
  `
  )}

  > div {
    width: 50%;

    ${getMediaQuery(
      device.md,
      () => `
    width: 100%;
  `
    )}
  }
`

export const StyledErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.75rem;
`

export const StyledFileErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.secondary.dark};
  font-size: 14px;
  font-weight: 400;
`

export const StyledFormStepWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const StyledEditCell = styled.div`
  display: inline-flex;
  justify-content: right;
  width: 100%;
`

interface StyledCircleProps {
  size?: number
  background?: string
  fontSize?: number
  hideBorder?: boolean
  borderColor?: string
  pointer?: boolean
  borderWeight?: number | string
  isClickable?: boolean
}

interface StyledBorderProps {
  hideBorder: boolean
  borderColor: string
  borderWeight: string
}

const getStyledBorder = (props: StyledBorderProps & { theme: DefaultTheme }): string => {
  if (props.hideBorder) {
    return `none`
  }

  if (props.borderColor) {
    return `${props.borderWeight || 1}px solid ${props.borderColor}`
  }

  return `1px solid ${props.theme.colors.grays.gray300}`
}

export const StyledCircle = styled.div<StyledCircleProps>`
  border: ${getStyledBorder};
  background: ${(props) => `${props.background}` || 'white'};
  width: ${(props) => (props.size ? `${props.size}px` : '47px')};
  height: ${(props) => (props.size ? `${props.size}px` : '47px')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '18px')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`

export const StyledShadedBox = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.grays.gray300};
  box-shadow: -12px 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 0.75rem;
  padding: ${(props) => props.padding ?? '1.25rem 1.5rem'};
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledSeparator = styled.div<{ marginTop?: number; marginBottom?: number }>`
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.colors.grays.gray300};
  margin-top: ${(props) => `${props.marginTop}px` ?? ''};
  margin-bottom: ${(props) => `${props.marginBottom}px` ?? ''};
`

const getIndicatorColor = (props: { color: string; theme: DefaultTheme }) => {
  switch (props.color) {
    case 'red': {
      return `
        background: ${props.theme.colors.secondary.dark};
      `
    }
    case 'yellow': {
      return `
        background: ${props.theme.colors.situational.warning};
      `
    }
    case 'green': {
      return `
        background: ${props.theme.colors.situational.success};
      `
    }
    default: {
      return `
        background: ${props.color};
      `
    }
  }
}

/**
 * Status indicator
 */
export const StyledStatusIndicator = styled.div<{ color: 'red' | 'yellow' | 'green' | string }>`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  ${getIndicatorColor}
`

export const StyledRoundIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  padding: 0.5rem;
  border-radius: 6px;
`

export const StyledReviewDataItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.grays.gray300};
  padding: 0.5rem 0;

  &:last-child {
    border-bottom: none;
  }
`

export const StyledPasswordCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 1.5rem;
`

export const StyledHiddenFileInput = styled.input`
  display: none;
`

export const StyledInlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;
`

export const StyledRoundBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: -1px 7px 21px 0 rgba(0, 0, 0, 0.04);
  padding: 1rem 0.75rem;
  gap: 1rem;
`

export const StyledAdminSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const StyledUnassignAdminBox = styled.div<{ top?: number; right?: number; bottom?: number; left?: number }>`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grays.gray300};
  background: white;
  box-shadow: -12px 7px 20px 0px rgba(0, 0, 0, 0.07);
  padding: 6px 10px;
  position: absolute;
  z-index: 10;
  top: ${(props) => (props.top ? `${props.top}px` : 'initial')};
  right: ${(props) => (props.right ? `${props.right}px` : 'initial')};
  bottom: ${(props) => (props.bottom ? `${props.bottom}px` : 'initial')};
  left: ${(props) => (props.left ? `${props.left}px` : 'initial')};

  .unassign-admin-box-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.grays.gray700};
  }
`

export const StyledActionBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.colors.grays.gray300};
  color: ${(props) => props.theme.colors.grays.gray600};
  padding: 0 1rem;
  font-size: 12px;
  position: relative;

  &:last-child {
    border-right: none;
  }
`

export const StyledDefaultCheckbox = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grays.gray400};
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.primary {
    background-color: ${(props) => props.theme.colors.primary.default};
  }
`

export const StyledWarningBox = styled.div<{
  top?: string
  right?: string
  bottom?: string
  left?: string
}>`
  position: absolute;
  z-index: 10;
  top: ${(props) => (props.top ? `${props.top}` : 'initial')};
  right: ${(props) => (props.right ? `${props.right}` : 'initial')};
  bottom: ${(props) => (props.bottom ? `${props.bottom}` : 'initial')};
  left: ${(props) => (props.left ? `${props.left}` : 'initial')};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.situational.warning};
  background: white;
  box-shadow: -12px 7px 20px 0px rgba(0, 0, 0, 0.07);
  padding: 10px 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.grays.gray700};
`

export const StyledLandingH1 = styled.h1<{ margin?: string; color?: string }>`
  font-size: 2rem;
  font-weight: 600;
  color: ${(props) => props.color || props.theme.colors.tertiary.medium};
  line-height: 2rem;
  margin: ${(props) => props.margin || '0'};
`

export const StyledLandingH2 = styled.h2<{ margin?: string; color?: string }>`
  font-size: 2.62rem;
  font-weight: 600;
  color: ${(props) => props.color || props.theme.colors.tertiary.medium};
  line-height: 2.8rem;
  margin: ${(props) => props.margin || '0'};
`

export const StyledLandingH3 = styled.h3<{ margin?: string; color?: string }>`
  font-size: 2rem;
  font-weight: 600;
  color: ${(props) => props.color || props.theme.colors.tertiary.medium};
  margin: ${(props) => props.margin || '0'};
`

export const StyledLandingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 3rem;
  padding: 5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.default};
`

export const StyledParagraph = styled.p<{ size?: string }>`
  font-size: ${(props) => props.size || '0.875rem'};
  color: ${(props) => props.theme.colors.grays.gray600};
  line-height: 1.5rem;
  font-weight: 400;
  margin: initial;
`

export const StyledMarketingSiteWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border-top: 1px solid ${(props) => props.theme.colors.secondary.medium};
`

export const StyledMarketingSection = styled.div<{ separator?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  justify-content: center;
  width: 100%;
  padding-bottom: 5rem;
  margin-top: 5rem;
  border-bottom: ${(props) => (props.separator ? `1px solid ${props.theme.colors.primary.medium}` : 'transparent')};
  position: relative;
`

export const StyledInnerSection = styled.section`
  width: 980px;
  display: flex;
  //margin: 0 auto 3rem auto;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  z-index: 9;

  ${getMediaQuery(
    device.lg,
    () => `
      width: 100%;
  `
  )}
`

export const StyledCenteredLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: -6rem 0 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: #eeeefd;
  gap: 2rem;
`

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const StyledSpinnerInputContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${spinAnimation} 2s infinite linear;
`

export const StyledSubtitle = styled.span`
  color: ${(props) => props.theme.colors.grays.gray600};
  font-size: 12px;
`

export const StyledPasswordLabelBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 1.5rem;
`
