import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslation from '../src/locales/en/translation.json'
import frTranslation from '../src/locales/fr/translation.json'
import srTranslation from '../src/locales/sr/translation.json'
import ruTranslation from '../src/locales/ru/translation.json'

export const defaultNamespace = 'translation'

const defaultLanguage = 'en'

export const resources = {
  en: {
    [defaultNamespace]: enTranslation,
  },
  fr: {
    [defaultNamespace]: frTranslation,
  },
  sr: {
    [defaultNamespace]: srTranslation,
  },
  ru: {
    [defaultNamespace]: ruTranslation,
  },
}

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    debug: true,
    returnEmptyString: false,
    ns: ['translation'],
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
