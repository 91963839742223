import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AdminPurchaseType, CommentType, CustomFileType, DocumentGroup } from 'Types/admin/admin'
import { PaginationProps } from 'Types/global.types.ts'

import {
  assignAdminToPurchase,
  assignReviewerToPurchase,
  getAdminPurchaseDetails,
  getAdminPurchases,
  getCustomFiles,
  removeAdminPurchase,
  updatePurchaseDetails,
  updatePurchasePaymentState,
  updatePurchaseState,
  uploadCustomFile,
  uploadPurchaseVehicleLicence,
  uploadPurchaseProform,
  uploadPurchaseSwift,
  updatePurchaseEur1Id,
  updatePurchasePrintDate,
  uploadCarDocument,
  uploadUserDocument,
  uploadPurchaseCarProform,
  getComments,
  postComment,
  updateAuctionHouse,
  updatePurchaseUserInformation,
  generatePurchaseEur1,
  uploadEoEur1Document,
  generateAttestationDate,
  generateCessionDate,
  setUserToPurchase,
  setExportationCountry,
} from 'Api/admin/admin.purchases.service.ts'
import { updateCustomer, uploadCustomerDocument } from 'Api/admin/admin.customers.service.ts'
import { cancelFacture } from 'Api/admin/admin.invoices.service.ts'
import { setAdminAuctionHouseToPurchase } from 'Api/admin/admin.auction.service.ts'

interface InitialStateProps {
  adminPurchasesList: AdminPurchaseType[]
  initialAdminPurchasesList: AdminPurchaseType[]
  purchaseDetails: AdminPurchaseType
  loading: boolean
  total: number
  pageIndex: number
  pageSize: number
  order: 'ASC' | 'DESC' | null
  sort: 'ORDER_NUMBER' | 'CREATED_AT'
  param: string
  maxDate: Date | null
  minDate: Date | null
  customFiles: CustomFileType[]
  carCustomFiles: CustomFileType[]
  userCustomFiles: CustomFileType[]
  swiftCustomFiles: CustomFileType[]
  comments: CommentType[]
  totalComments: number
  pageIndexComments: number
  pageSizeComments: number
  commentPagesNumber: number
  customsStatus: string
}

const initialState: InitialStateProps = {
  adminPurchasesList: [],
  initialAdminPurchasesList: [],
  purchaseDetails: null,
  loading: false,
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  order: null,
  sort: 'CREATED_AT',
  param: '',
  maxDate: null,
  minDate: null,
  customsStatus: '',
  customFiles: [],
  carCustomFiles: [],
  userCustomFiles: [],
  swiftCustomFiles: [],
  comments: [],
  totalComments: 0,
  pageIndexComments: 0,
  pageSizeComments: 10,
  commentPagesNumber: 0,
}

const adminPurchasesSlice = createSlice({
  name: 'adminPurchases',
  initialState,
  reducers: {
    setCommentsPageIndex: (state) => {
      const nextPageIndex = state.pageIndexComments + 1
      if (nextPageIndex < state.commentPagesNumber) {
        state.pageIndexComments += 1
      }
    },
    setPurchaseListPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload
    },
    setOrder: (state, action: PayloadAction<'ASC' | 'DESC'>) => {
      if (action.payload) {
        state.order = action.payload
      }
    },
    setSort: (state, action: PayloadAction<'ORDER_NUMBER' | 'CREATED_AT'>) => {
      if (action.payload) {
        state.sort = action.payload
      }
    },
    setMinDate: (state, action: PayloadAction<Date>) => {
      if (action.payload) {
        state.minDate = action.payload
      }
    },
    setMaxDate: (state, action: PayloadAction<Date>) => {
      if (action.payload) {
        state.maxDate = action.payload
      }
    },
    setCustomsStatus: (state, action) => {
      if (action.payload) {
        state.customsStatus = action.payload
      }
    },
    resetPurchaseDetailsState: (state) => {
      state.purchaseDetails = null
      state.comments = []
      state.totalComments = 0
      state.pageIndexComments = 0
      state.pageSizeComments = 10
    },
    resetPurchaseList: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminPurchases.fulfilled, (state, action) => {
        state.adminPurchasesList = action.payload.adminPurchaseList
        state.initialAdminPurchasesList = action.payload.adminPurchaseList
        state.total = action.payload.total
        state.loading = false
      })
      .addCase(getAdminPurchases.pending, (state) => {
        state.loading = true
      })
      .addCase(getAdminPurchases.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAdminPurchaseDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
        state.loading = false
      })
      .addCase(getAdminPurchaseDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getAdminPurchaseDetails.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadPurchaseCarProform.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.adminPurchase.id) {
              return action.payload.adminPurchase
            }
            return purchase
          })
          state.purchaseDetails = action.payload.adminPurchase
        }
        state.loading = false
      })
      .addCase(uploadPurchaseProform.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.adminPurchase.id) {
              return action.payload.adminPurchase
            }
            return purchase
          })
          state.purchaseDetails = action.payload.adminPurchase
        }
        state.loading = false
      })
      .addCase(uploadPurchaseProform.pending, (state) => {
        state.loading = true
      })
      .addCase(uploadPurchaseProform.rejected, (state) => {
        state.loading = false
      })
      .addCase(uploadPurchaseVehicleLicence.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.adminPurchase.id) {
              return action.payload.adminPurchase
            }
            return purchase
          })
        }
        state.loading = false
      })
      .addCase(uploadPurchaseVehicleLicence.pending, (state) => {
        state.loading = true
      })
      .addCase(uploadPurchaseVehicleLicence.rejected, (state) => {
        state.loading = false
      })
      .addCase(assignAdminToPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.adminPurchase.id) {
              return action.payload.adminPurchase
            }
            return purchase
          })

          if (state.purchaseDetails && state.purchaseDetails?.id === action.payload.adminPurchase.id) {
            state.purchaseDetails = action.payload.adminPurchase
          }
        }
      })
      .addCase(assignReviewerToPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.adminPurchase.id) {
              return action.payload.adminPurchase
            }
            return purchase
          })

          if (state.purchaseDetails && state.purchaseDetails?.id === action.payload.adminPurchase.id) {
            state.purchaseDetails = action.payload.adminPurchase
          }
        }
      })
      .addCase(updatePurchaseState.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.purchaseState.id) {
              return {
                ...purchase,
                state: action.payload.purchaseState.state,
              }
            } else {
              return purchase
            }
          })

          if (state.purchaseDetails && state.purchaseDetails?.id === action.payload.purchaseState.id) {
            state.purchaseDetails = {
              ...state.purchaseDetails,
              state: action.payload.purchaseState.state,
            }
          }
        }
      })
      .addCase(updatePurchasePaymentState.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchasePaymentState
        }
      })
      .addCase(getCustomFiles.fulfilled, (state, action) => {
        if (action.payload) {
          const { group } = action.payload
          switch (group as DocumentGroup) {
            case 'PURCHASE_DOCS': {
              state.customFiles = action.payload.customFiles
              break
            }
            case 'USER_DOCS': {
              state.userCustomFiles = action.payload.customFiles
              break
            }
            case 'CAR_DOCS': {
              state.carCustomFiles = action.payload.customFiles
              break
            }
            case 'SWIFT_DOCS': {
              state.swiftCustomFiles = action.payload.customFiles
              break
            }
          }
        }
      })
      .addCase(uploadUserDocument.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
      .addCase(uploadEoEur1Document.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
      .addCase(uploadCarDocument.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
      .addCase(uploadCustomFile.fulfilled, (state, action) => {
        if (action.payload?.customFile) {
          const group: DocumentGroup = action.payload.customFile.group

          switch (group) {
            case 'PURCHASE_DOCS': {
              state.customFiles.unshift(action.payload.customFile)
              break
            }
            case 'CAR_DOCS': {
              state.carCustomFiles.unshift(action.payload.customFile)
              break
            }
            case 'USER_DOCS': {
              state.userCustomFiles.unshift(action.payload.customFile)
              break
            }
            case 'SWIFT_DOCS': {
              state.swiftCustomFiles.unshift(action.payload.customFile)
            }
          }
        }
      })
      .addCase(updatePurchaseDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails

          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.purchaseDetails.id) {
              return action.payload.purchaseDetails
            } else {
              return purchase
            }
          })
        }
      })
      .addCase(uploadPurchaseSwift.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(removeAdminPurchase.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.adminPurchasesList = state.adminPurchasesList.filter(
            (purchase) => purchase.id !== action.payload.purchaseId
          )
        }
      })
      .addCase(updatePurchaseEur1Id.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.purchase.id) {
              return action.payload.purchase
            } else {
              return purchase
            }
          })

          if (state.purchaseDetails && state.purchaseDetails?.id === action.payload.purchase.id) {
            state.purchaseDetails = action.payload.purchase
          }
        }
      })
      .addCase(updatePurchasePrintDate.fulfilled, (state, action) => {
        if (action.payload) {
          state.adminPurchasesList = state.adminPurchasesList.map((purchase) => {
            if (purchase.id === action.payload.purchase.id) {
              return action.payload.purchase
            } else {
              return purchase
            }
          })

          if (state.purchaseDetails && state.purchaseDetails?.id === action.payload.purchase.id) {
            state.purchaseDetails = action.payload.purchase
          }
        }
      })
      .addCase(getComments.fulfilled, (state, action) => {
        if (action.payload) {
          state.commentPagesNumber = action.payload.total / state.pageSizeComments
          state.comments.unshift(...action.payload.comments)
          state.totalComments += action.payload.total
        }
      })
      .addCase(postComment.fulfilled, (state, action) => {
        if (action.payload) {
          state.comments.push(action.payload.comment)
        }
      })
      .addCase(updateAuctionHouse.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(updatePurchaseUserInformation.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        if (action.payload && state.purchaseDetails?.user?.id === action.payload.customer?.id) {
          state.purchaseDetails.user = action.payload.customer
        }
      })
      .addCase(uploadCustomerDocument.fulfilled, (state, action) => {
        if (action.payload && state.purchaseDetails?.user?.id === action.payload.customer.id) {
          state.purchaseDetails.user = action.payload.customer
        }
      })
      .addCase(generatePurchaseEur1.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(generateAttestationDate.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(generateCessionDate.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchaseDetails
        }
      })
      .addCase(cancelFacture.fulfilled, (state, action) => {
        if (action.payload && action.payload.entityType === 'PURCHASE') {
          state.purchaseDetails.facture = action.payload.facture
          state.purchaseDetails.paymentState = 'CANCELED'
        }
      })
      .addCase(setAdminAuctionHouseToPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
      .addCase(setUserToPurchase.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
      .addCase(setExportationCountry.fulfilled, (state, action) => {
        if (action.payload) {
          state.purchaseDetails = action.payload.purchase
        }
      })
  },
})

const { actions } = adminPurchasesSlice

export const {
  setParam,
  setSort,
  setOrder,
  setMinDate,
  setMaxDate,
  resetPurchaseDetailsState,
  setCommentsPageIndex,
  setPurchaseListPageIndex,
  setCustomsStatus,
  resetPurchaseList,
} = actions

export default adminPurchasesSlice.reducer
