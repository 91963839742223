import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { AdminPurchaseResponse } from 'Types/admin/admin'
import { AuctionHouseApiType } from 'Schemas/auctionHouse.types.ts'
import { AuctionHouseType } from 'Types/auctionHouse'

import { ADMIN_API_ROOT } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

export const getAdminAuctionHouse = createAsyncThunk(
  'admin/getAdminAuctionHouse',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const url = `${ADMIN_API_ROOT}/auction-house?page=${pageIndex}&limit=${pageSize}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AuctionHouseType[]; total: number }> = await axios.get(url, config)
      return {
        adminAuctionHouseList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchAdminAuctionHouse = createAsyncThunk(
  'admin/searchAdminAuctionHouse',
  async ({ param, pageIndex, pageSize }: { param: string; pageIndex: number; pageSize: number }) => {
    const url = `${ADMIN_API_ROOT}/auction-house/search?param=${param}&page=${pageIndex}&limit=${pageSize}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AuctionHouseType[]; total: number }> = await axios.get(url, config)
      return {
        adminAuctionHouseList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const addAdminAuctionHouse = createAsyncThunk(
  'admin/addAdminAuctionHouse',
  async (options: AuctionHouseApiType) => {
    const url = `${ADMIN_API_ROOT}/auction-house`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AuctionHouseApiType }> = await axios.post(url, options, config)
      return {
        adminAuctionHouse: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const updateAdminAuctionHouse = createAsyncThunk(
  'admin/updateAdminAuctionHouse',
  async ({ auctionHouseId, options }: { auctionHouseId: string; options: AuctionHouseApiType }) => {
    const url = `${ADMIN_API_ROOT}/auction-house/${auctionHouseId}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<{ data: AuctionHouseApiType }> = await axios.patch(url, options, config)
      return {
        adminAuctionHouse: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const setAdminAuctionHouseToPurchase = createAsyncThunk(
  'admin/setAdminAuctionHouseToPurchase',
  async ({ purchaseId, auctionHouseId }: { purchaseId: string; auctionHouseId: string }) => {
    const url = `${ADMIN_API_ROOT}/purchase/${purchaseId}/auction-house`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<AdminPurchaseResponse> = await axios.patch(url, { auctionHouseId }, config)
      return {
        purchase: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
