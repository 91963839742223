import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CustomsCrossing, PaginationProps } from 'Types/global.types.ts'

import {
  getCustomsCrossingList,
  updateCustomsCrossing,
  addCustomsCrossing,
  removeCustomsCrossing,
} from 'Api/admin/admin.customscrossing.service'

interface InitialStateProps {
  customsCrossingList: CustomsCrossing[]
  total: number
  loading: boolean
  pageIndex: number
  pageSize: number
  param: string
}

const initialState: InitialStateProps = {
  customsCrossingList: [],
  total: 0,
  loading: false,
  pageIndex: 0,
  pageSize: 50,
  param: '',
}

const customsCrossingSlice = createSlice({
  name: 'customsCrossingList',
  initialState,
  reducers: {
    setCustomsCrossingPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
    setParam: (state, action) => {
      state.param = action.payload
      state.pageIndex = 0
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsCrossingList.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsCrossingList = action.payload.customsCrossingList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getCustomsCrossingList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsCrossingList.rejected, (state) => {
        state.loading = false
      })
      .addCase(removeCustomsCrossing.fulfilled, (state, action) => {
        if (action.payload.deleted) {
          state.customsCrossingList = state.customsCrossingList.filter((customsCrossing) => customsCrossing.id !== action.payload.customsCrossingId)
        }
      })
      .addCase(addCustomsCrossing.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsCrossingList.unshift(action.payload.customsCrossing)
        }
      })
      .addCase(updateCustomsCrossing.fulfilled, (state, action) => {
        state.customsCrossingList = state.customsCrossingList.map((customsCrossing) => {
          if (customsCrossing.id === action.payload.customsCrossing.id) {
            return action.payload.customsCrossing
          }
          return customsCrossing
        })
      })
  },
})

const { actions } = customsCrossingSlice
export const { setCustomsCrossingPageIndex, setParam } = actions
export default customsCrossingSlice.reducer
