import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CustomsVariable, PaginationProps } from 'Types/global.types.ts'

import { getCustomsVariableList, updateCustomsVariable } from 'Api/admin/admin.customsVariables.service.ts'
import { formatArrayToObject } from 'Utils/common.util.ts'

interface InitialStateProps {
  customsVariableList: CustomsVariable[]
  initialCustomsVariableList: { [key: string]: string }
  diff: { [key: string]: string }
  total: number
  loading: boolean
  pageIndex: number
  pageSize: number
}

const initialState: InitialStateProps = {
  customsVariableList: [],
  initialCustomsVariableList: {},
  diff: {},
  total: 0,
  loading: false,
  pageIndex: 0,
  pageSize: 50,
}

const customsVariableSlice = createSlice({
  name: 'customsVariableList',
  initialState,
  reducers: {
    setCustomsVariables: (state, action: { payload: { id: string; value: string } }) => {
      if (action.payload) {
        state.customsVariableList = state.customsVariableList.map((variable) => {
          if (variable.id === action.payload.id) {
            return {
              ...variable,
              value: action.payload.value,
            }
          } else {
            return variable
          }
        })

        state.diff[action.payload.id] = action.payload.value
      }
    },
    setCustomsVariablePageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomsVariableList.fulfilled, (state, action) => {
        if (action.payload) {
          state.customsVariableList = action.payload.customsVariableList
          state.initialCustomsVariableList = formatArrayToObject(action.payload.customsVariableList)
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getCustomsVariableList.pending, (state) => {
        state.loading = true
      })
      .addCase(getCustomsVariableList.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateCustomsVariable.fulfilled, (state, action) => {
        if (action.payload) {
          const mutableCustomsVariableList = state.customsVariableList.map((customsVariable) => {
            if (customsVariable.id === action.payload.customsVariable.id) {
              return action.payload.customsVariable
            } else {
              return customsVariable
            }
          })
          state.customsVariableList = mutableCustomsVariableList
          state.initialCustomsVariableList = formatArrayToObject(mutableCustomsVariableList)
          state.diff = {}
        }
      })
  },
})

const { actions } = customsVariableSlice
export const { setCustomsVariablePageIndex, setCustomsVariables } = actions
export default customsVariableSlice.reducer
