import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { CustomsVariableResponse, CustomsVariableResponseList } from 'Types/global.types'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

interface RequestPayload {
  pageIndex: number
  pageSize: number
}

/**
 * Get list of customs variables
 */
export const getCustomsVariableList = createAsyncThunk(
  'admin/getCustomsVariableList',
  async ({ pageIndex, pageSize }: RequestPayload) => {
    const url = `${ADMIN_API_URL.CUSTOMS_VARIABLE.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomsVariableResponseList> = await axios.get(url, config)
      return {
        customsVariableList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

/**
 * Update customs variables
 */
export const updateCustomsVariable = createAsyncThunk(
  'admin/updateCustomsVariable',
  async (options: Array<{ id: string; value: string }>) => {
    const url = `${ADMIN_API_URL.CUSTOMS_VARIABLE.ROOT}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomsVariableResponse> = await axios.patch(url, options, config)
      return {
        customsVariable: data.data,
      }
    } catch (error) {
      handleError(error)
    }
  }
)
