import styled from 'styled-components'
import { getMediaQuery, device } from 'Styles/Breakpoints.ts'

export const StyledPortal = styled.div`
  display: flex;
  position: fixed;
  inset: 0;
  align-items: center;
  justify-content: center;
  z-index: 1001;
`

export const StyledPortalOverlay = styled.div`
  display: flex;
  position: absolute;
  inset: 0;
  background: white;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  opacity: 0.5;
  transition: all 0.3s linear;
  z-index: 1002;
`

export const StyledPortalModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  width: 30rem;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  padding: 2rem;
  box-shadow: -12px 7px 20px 0 rgba(0, 0, 0, 0.07);
  gap: 2rem;
  z-index: 1003;

  ${getMediaQuery(
    device.sm,
    () => `
    width: auto;
    margin: 1rem;
  `
  )}
`

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .close-icon {
    cursor: pointer;
  }
`

export const StyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
`
