import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationProps } from 'Types/global.types.ts'
import { getDhl, orderDhl } from 'Api/dhl.service.ts'

type DhlSliceProps = {
  dhlList: Array<{ [key: string]: any }>
  total: number
  pageIndex: number
  pageSize: number
  loading: boolean
}

const initialState = {
  dhlList: [],
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  loading: false,
} as DhlSliceProps

const dhlListSlice = createSlice({
  name: 'dhl',
  initialState,
  reducers: {
    setDhlPageIndex: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDhl.fulfilled, (state, action) => {
        if (action.payload) {
          // @ts-ignore
          state.dhlList = action.payload.dhlList
          state.total = action.payload.total
        }
        state.loading = false
      })
      .addCase(getDhl.pending, (state) => {
        state.loading = true
      })
      .addCase(getDhl.rejected, (state) => {
        state.loading = false
      })
      .addCase(orderDhl.fulfilled, (state, action) => {
        if (action.payload) {
          state.dhlList.unshift(action.payload)
          state.total += 1
          state.pageIndex = 0
        }
        state.loading = false
      })
      .addCase(orderDhl.pending, (state) => {
        state.loading = true
      })
      .addCase(orderDhl.rejected, (state) => {
        state.loading = false
      })
  },
})

const { actions } = dhlListSlice
export const { setDhlPageIndex } = actions

export default dhlListSlice.reducer
