import { connect } from 'react-redux'

import Drawer from './Drawer.component.tsx'
import { RootState } from 'Stores/store.ts'

const mapStateToProps = ({ drawer }: RootState) => ({ drawer })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
