import React from 'react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DrawerOptionProps, DrawerProps } from 'Components/common/Drawer/Drawer.types.ts'

const initialDrawerOptions: DrawerOptionProps = {
  drawerTitle: '',
  activeStep: undefined,
}

const initialState: DrawerProps = {
  isOpen: false,
  type: null,
  options: initialDrawerOptions,
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<[boolean, React.ComponentType?, DrawerOptionProps?]>) => {
      const [isOpen, type, options] = action.payload
      state.isOpen = isOpen

      if (type) {
        state.type = type
      }

      if (options) {
        state.options = options
      }
    },
    closeDrawer: (state) => {
      state.isOpen = false
      state.type = null
      state.options = initialDrawerOptions
    },
  },
})

export const { openDrawer, closeDrawer } = drawerSlice.actions
export default drawerSlice.reducer
