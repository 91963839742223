import { StyledToastContainer } from './Toastify.styled.ts'
import { contextClass, getIconByType } from 'Components/common/Toastify/Toastify.config.tsx'

const Toastify = () => (
  <StyledToastContainer
    icon={({ type }) => getIconByType(type)}
    position="top-right"
    toastClassName={({ type }) => contextClass[type || 'default'] + ' toast-item'}
    hideProgressBar={true}
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  />
)

export default Toastify
