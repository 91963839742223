import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { CustomsCrossing, CustomsResponse, CustomsResponseList } from 'Types/global.types'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'
import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

interface RequestPayload {
  pageIndex: number
  pageSize: number
  param?: string
}

export const getCustomsCrossingList = createAsyncThunk(
  'admin/getCustomsCrossingList',
  async ({ pageIndex, pageSize, param }: RequestPayload) => {
    let url = `${ADMIN_API_URL.CUSTOMS_CROSSING.ROOT}/search`

    const queryParams = []
    if (param || param === '') {
      queryParams.push(`param=${encodeURIComponent(param)}`)
    }

    if (queryParams.length > 0) {
      const queryString = queryParams.join('&')
      url = `${url}?${queryString}&page=${pageIndex}&limit=${pageSize}`
    }

    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomsResponseList> = await axios.get(url, config)
      return {
        customsCrossingList: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

export const updateCustomsCrossing = createAsyncThunk(
  'admin/updateCustomsCrossing',
  async ({ id, options }: { id: number; options: CustomsCrossing }) => {
    const url = `${ADMIN_API_URL.CUSTOMS_CROSSING.ROOT}/${id}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomsResponse> = await axios.patch(url, options, config)
      return {
        customsCrossing: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const addCustomsCrossing = createAsyncThunk(
  'admin/addCustomsCrossing',
  async ({ options }: { options: CustomsCrossing }) => {
    const url = `${ADMIN_API_URL.CUSTOMS_CROSSING.ROOT}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<CustomsResponse> = await axios.post(url, options, config)
      return {
        customsCrossing: data.data,
      }
    } catch (error) {
      handleError(error)
    }
  }
)

export const removeCustomsCrossing = createAsyncThunk('admin/removeCustomsCrossing', async (id: number) => {
  const url = `${ADMIN_API_URL.CUSTOMS_CROSSING.ROOT}/${id}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomsResponseList> = await axios.delete(url, config)
    return {
      customsCrossingId: id,
      deleted: data.data,
    }
  } catch (error) {
    handleError(error)
  }
})

/**
 * Search Customs Crossing for admin
 * @param param
 * @param pageIndex
 * @param pageSize
 */
export const searchCustomsCrossingList = async ({ param, pageIndex, pageSize }: RequestPayload) => {
  const url = `${ADMIN_API_URL.CUSTOMS_CROSSING.ROOT}/search?param=${param}&page=${pageIndex}&limit=${pageSize}`
  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<CustomsResponseList> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error)
  }
}

// http://localhost:3000/admin/customs-crossing/search?param=Allemagne
